import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { roleService, userService } from '@/services';

function CreateUser() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState({
    teamId: 0,
    name: '',
    email: '',
    password: '',
    role: []
  });

  const [roles, setRoles] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id) {
      await userService.update(id, user);
    } else {
      await userService.create(user);
    }
    navigate('/admin/user');
  };

  useEffect(() => {
    const getAllRoles = async () => {
      let res = await roleService.all();
      setRoles(res.data.roles);
    };
    getAllRoles();
  }, [id]);

  useEffect(() => {
    const getUser = async () => {
      let res = await userService.find(id);
      let user = res.data.user;
      setUser({
        name: user.full_name,
        email: user.email,
        password: '123456',
        role: user.roles.map((role) => role.id),
        teamId: user.team_id
      });
    };
    if (id) {
      getUser();
    }
  }, [id]);

  const handleChangeRole = (id) => {
    if (user.role.includes(id)) {
      setUser({ ...user, role: [...user.role.filter((role) => role !== id)] });
    } else {
      setUser({ ...user, role: [...user.role, id] });
    }
  };

  return (
    <div className="create-user">
      <Link to="/admin/user" className="inline-block btn-normal">
        <span className="mr-2">
          <i className="fa-solid fa-chevron-left"></i>
        </span>
        Back to form
      </Link>
      <h1 className="text-center font-semibold text-2xl">Create new User</h1>
      <div className="flex justify-center">
        <form className="w-1/3 my-3" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Name
            </label>
            <input
              type="name"
              id="name"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder=""
              required
              onChange={(e) => setUser({ ...user, name: e.target.value })}
              value={user.name}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder=""
              required
              onChange={(e) => setUser({ ...user, email: e.target.value })}
              value={user.email}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder=""
              required
              onChange={(e) => setUser({ ...user, password: e.target.value })}
              value={user.password}
            />
          </div>
          <div className="form-group my-4 flex items-center">
            <label
              htmlFor=""
              className="font-semibold inline-block min-w-[100px]"
            >
              Category
            </label>
            <select
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2"
              onChange={(e) => setUser({ ...user, teamId: e.target.value })}
              value={user.teamId}
              required
            >
              <option value="0">Choose a Team</option>
              <option value="1">L&D</option>
              <option value="2">PHP</option>
              <option value="3">Javascript</option>
              <option value="4">Ruby on Rails</option>
              <option value="5">GoLang</option>
              <option value="6">Mobile</option>
            </select>
          </div>
          <div className="form-group my-4 flex items-center">
            <label
              htmlFor=""
              className="font-semibold inline-block min-w-[100px]"
            >
              Role
            </label>
            <div className="flex flex-col">
              {roles.map((role, index) => {
                return (
                  <div key={index}>
                    <input
                      type="checkbox"
                      value={role.id}
                      id={role.id}
                      name="role"
                      onChange={() => handleChangeRole(role.id)}
                      checked={user.role.includes(role.id)}
                    />
                    <label
                      htmlFor={role.id}
                      className="inline-block mt-2 ml-2 cursor-pointer"
                    >
                      {role.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex justify-center">
            <button className="btn-main">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateUser;
