function QuizSection({ quizzes, addNewQuiz, deleteQuiz, changeValue }) {
  return (
    <div>
      {quizzes.map((quiz, quizId) => {
        return (
          <div key={quizId} className="flex justify-between">
            <div className="flex-1">
              #{quizId + 1}.
              <input
                type="text"
                onChange={(e) =>
                  changeValue(quizId, 'question', e.target.value)
                }
                value={quiz.question}
                className="input mx-2 border rounded w-3/4 p-2 font-semibold"
                required
              />
              {quiz.answers.map((answer, answerId) => {
                return (
                  <div key={answerId} className="my-3.5 flex items-center">
                    <input
                      id={`${quizId}-${answerId}`}
                      type="radio"
                      name={'quiz' + quizId}
                      className="w-3.5 h-3.5 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                      onChange={(e) =>
                        changeValue(quizId, 'check', true, answerId)
                      }
                      checked={answer.isCorrect}
                    />
                    <input
                      type="text"
                      className="input mx-2 border rounded w-1/2 p-2"
                      onChange={(e) =>
                        changeValue(quizId, 'answer', e.target.value, answerId)
                      }
                      value={answer.text}
                      required
                    />
                  </div>
                );
              })}
            </div>
            <button
              className="mx-4 text-red-500"
              onClick={(e) => deleteQuiz(e, quizId)}
            >
              <i className="fa-solid fa-circle-minus"></i>
            </button>
          </div>
        );
      })}
      <button className="btn-main" onClick={addNewQuiz}>
        <span className="mx-2">
          <i className="fa-solid fa-circle-plus"></i>
        </span>
        Add New Quizz
      </button>
    </div>
  );
}

export default QuizSection;
