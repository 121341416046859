import request from '@/request';

import common from './common';

const lessonService = {
  ...common('lessons'),
  async handleImageUpload(data) {
    return await request.post('/lessons/image-upload', data);
  },
  async getLessonByCourseIdAndUserId(data) {
    return await request.post('/lessons/user-lesson', data);
  },
  async changeLesson(data) {
    return await request.post('/studies/change-lesson', data);
  },
  async updateAssignment(data) {
    return await request.post('/studies/update-assignment', data);
  },
  async updatePoint(data) {
    return await request.post('/studies/update-point', data);
  },
  async receiveCertificate(courseId, data) {
    return await request.post(`/studies/receive-certificate/${courseId}`, data);
  }
};

export { lessonService };
