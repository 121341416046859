import request from '@/request';

import common from './common';

const roleService = {
  ...common('roles'),
  async getAllPermissions() {
    return await request.get('/permissions');
  },
  async changePermission(value) {
    return await request.post('/roles/change-permission', value);
  },
  async getPermissionsByUser() {
    return await request.get('/permissions/by-user');
  }
};
export { roleService };
