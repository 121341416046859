import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { authService } from '@/services';
import {
  getAuthUser,
  removeAuthToken,
  removeAuthUser
} from '@/utils/localStorageUtil';

function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useRef(getAuthUser());

  const { isAdmin, permissions } = useSelector((state) => state.permission);

  const sidebars = useRef([
    ['/', 'fa-solid fa-house-chimney', 'Dashboard', 'dashboard_access'],
    ['/myclass', 'fa-solid fa-bars', 'MyClass', 'dashboard_access'],
    [
      `/profile/${user.current.id}`,
      'fa-solid fa-inbox',
      'Profile',
      'dashboard_access'
    ],
    ['/admin/course', 'fa-solid fa-laptop', 'Courses', 'course_edit'],
    ['/admin/user', 'fa-solid fa-user', 'Users', 'user_access'],
    ['/admin/role', 'fa-solid fa-gears', 'Roles', 'role_access'],
    ['/rank', 'fa-solid fa-users', 'Rank', 'dashboard_access']
  ]);

  const logout = async () => {
    await authService.logout();
    removeAuthToken();
    removeAuthUser();
    navigate('/login');
  };

  return (
    <div className="w-[16%] fixed top-0 left-0 bottom-0 border-r">
      <div className="overflow-y-auto bg-white h-full">
        <Link
          to="/"
          className="flex items-center justify-center text-2xl font-bold my-4 text-black"
        >
          <span className="mx-2">
            <i className="fa-solid fa-code"></i>
          </span>
          GCodePlay
        </Link>
        <ul className="mt-5 mx-3 space-y-2">
          {sidebars.current
            .filter(([route, icon, routeName, alias], index) => {
              return isAdmin || permissions.includes(alias);
            })
            .map(([route, icon, routeName], index) => {
              return (
                <li key={index}>
                  <Link
                    to={route}
                    className={`flex items-center py-2.5 px-10 text-lg font-semibold
                  rounded-full transition-all ${
                    location.pathname === route
                      ? 'bg-[#5350f7] text-white'
                      : ' text-gray-500 '
                  }
                  `}
                  >
                    <span className="inline-block min-w-[20px]">
                      <i className={icon}></i>
                    </span>
                    <span className="ml-3">{routeName}</span>
                  </Link>
                </li>
              );
            })}
          <li className="py-2.5">
            <button
              className="flex items-center px-10 font-semibold text-lg text-gray-500 
                  rounded transition-all"
              onClick={logout}
            >
              <span className="inline-block min-w-[20px]">
                <i className="fa-solid fa-arrow-right-to-bracket"></i>
              </span>
              <span className="ml-3">Logout</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
