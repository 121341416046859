import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Pagination from '@/components/Pagination';
import { Team } from '@/constants';
import { useDebounce } from '@/hooks';
import { userService } from '@/services';

import ListUser from './components/ListUser';

function Rank() {
  const location = useLocation();
  const [users, setUsers] = useState([]);
  const [top5Users, setTop5Users] = useState([]);
  const [mostBadgeUsers, setMostBadgeUsers] = useState([]);
  const [query, setQuery] = useState('');
  const [team, setTeam] = useState('');
  const [page, setPage] = useState(1);
  const debouncedValue = useDebounce(team, 500);
  const [meta, setMeta] = useState({
    total: 0,
    per_page: 0,
    last_page: 0,
    current_page: 0
  });
  useEffect(() => {
    const getUserRank = async () => {
      let { data } = await userService.rank(query, page);
      setUsers(data.users);
      setTop5Users(data.top5Users);
      setMostBadgeUsers(data.mostBadgeUsers);
      setMeta(data.meta);
    };
    getUserRank();
  }, [query, page]);

  useEffect(() => {
    const getUserRank = async () => {
      let { data } = await userService.rank(query, page, debouncedValue);
      setUsers(data.users);
      setMeta(data.meta);
    };
    getUserRank();
  }, [debouncedValue]);

  const changePage = (page) => {
    if (page !== 0 && page !== meta.last_page + 1) {
      setPage(page);
      window.history.replaceState(
        null,
        'rank',
        `${location.search}&page=${page}`
      );
    }
  };

  const handleChangeQuery = (name, value) => {
    if (query.includes('asc')) {
      setQuery(`${name} desc`);
      window.history.replaceState(null, 'rank', `?${name + ' desc'}`);
    } else {
      setQuery(`${name} asc`);
      window.history.replaceState(null, 'rank', `?${name + ' asc'}`);
    }
  };

  return (
    <div className="rank">
      <div className="flex justify-between">
        <div className="w-1/2 mr-2">
          <h1 className="my-3 text-blue-500">Top 5 User With Highest Rank</h1>
          <ListUser users={top5Users || []} />
        </div>
        <div className="w-1/2 ml-2">
          <h1 className="my-3 text-blue-500">User of The Month</h1>
          <ListUser users={mostBadgeUsers || []} />
        </div>
      </div>
      <h1 className="my-3 text-blue-500">All Users</h1>
      <input
        type="text"
        className="input rounded mt-2 w-1/4 border-b pb-1"
        placeholder="Search by Team"
        value={team}
        onChange={(e) => setTeam(e.target.value)}
      />
      <table className="table divide-y rounded-lg w-full overflow-x-auto mt-2">
        <thead className="bg-gray-50">
          <tr className="whitespace-nowrap text-left bg-gray-100">
            <th scope="col" className="py-3 px-2 text-sm text-gray-500">
              UserName
            </th>
            <th
              scope="col"
              className="py-3 px-2 text-sm text-gray-500 cursor-pointer"
            >
              Rank
              <button
                className="ml-2 px-1"
                onClick={() => handleChangeQuery('point')}
              >
                <i className="fa-solid fa-sort"></i>
              </button>
            </th>
            <th scope="col" className="py-3 px-2 text-sm text-gray-500">
              RankName
            </th>
            <th scope="col" className="py-3 px-2 text-sm text-gray-500">
              Badge
            </th>
            <th
              scope="col"
              className="py-3 px-2 text-sm text-gray-500 cursor-pointer"
            >
              Point
              <button
                className="ml-2 px-1"
                onClick={() => handleChangeQuery('point')}
              >
                <i className="fa-solid fa-sort"></i>
              </button>
            </th>
            <th
              scope="col"
              className="py-3 px-2 text-sm text-gray-500 cursor-pointer"
            >
              Team
              <button className="ml-2">
                <i className="fa-solid fa-sort"></i>
              </button>
            </th>
            <th scope="col" className="py-3 px-2 text-sm text-gray-500">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y">
          {users.map((user, index) => {
            return (
              <tr key={index} className="whitespace-nowrap">
                <td className="py-4 px-2 font-semibold hover:text-blue-500 hover:underline">
                  <Link to={`/profile/${user.id}`}>@{user.full_name}</Link>
                </td>
                <td className="py-4 px-2 text-lg">#{user.rank.id}</td>
                <td className="py-4 px-2 font-medium">{user.rank.name}</td>
                <td className="py-4 px-2">
                  <img
                    src={user.rank.badge}
                    className="h-16 w-1/2 object-fill"
                    alt=""
                  />
                </td>
                <td className="py-4 px-2">
                  <span className="mr-2 text-sm text-yellow-500">
                    <i className="fa-solid fa-coins"></i>
                  </span>
                  {user.point}
                </td>
                <td className="py-4 px-2">
                  <span
                    className={`px-2 py-1 text-sm font-semibold rounded ${
                      Team[user.team.toLowerCase()]
                    }`}
                  >
                    {user.team}
                  </span>
                </td>
                <td>
                  <Link to={`/profile/${user.id}`}>
                    <span className="btn-main text-sm">
                      <i className="fa-solid fa-eye mr-2"></i>
                      View Profile
                    </span>
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {meta.last_page > 1 && (
        <div className="fixed bottom-2 right-2">
          <Pagination
            currentPage={meta.current_page}
            lastPage={meta.last_page}
            changePage={changePage}
          />
        </div>
      )}
    </div>
  );
}
export default Rank;
