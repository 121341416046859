import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import images from '@/assets/images';
import { authService } from '@/services';
import { addPermissions } from '@/store/modules/permissionSlice';
import { setAuthToken, setAuthUser } from '@/utils/localStorageUtil';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    email: '',
    password: ''
  });
  const [passwordType, setPasswordType] = useState('password');
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let { data } = await authService.login(user);
    console.log(data);
    if (data.status === false) {
      setError(true);
    } else {
      setAuthToken(data.token.access_token);
      setAuthUser(data.user);
      dispatch(
        addPermissions({
          permissions: data.permissions.map((permisson) => permisson.name)
        })
      );
      navigate('/');
    }
  };

  const changePasswordType = (e) => {
    e.preventDefault();
    if (passwordType === 'text') {
      setPasswordType('password');
    } else {
      setPasswordType('text');
    }
  };
  return (
    <div
      className="login flex items-center justify-center h-[100vh] w-full"
      style={{
        backgroundImage: `url(${images.background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}
    >
      <form
        action=""
        className="p-10 rounded-3xl w-[380px] border shadow bg-[#fff]"
      >
        <div className="flex flex-col items-center justify-center mb-6">
          <h2 className="text-2xl text-center font-bold my-3">
            CodePlay Login
          </h2>
          <p className="my-1 text-center font-medium text-sm">
            Hey, Enter your details to get sign in to your account
          </p>
        </div>
        <input
          type="text"
          name=""
          id="email"
          className="p-2 text-sm border border-gray-200 rounded-md outline-none my-2 w-full"
          placeholder="Enter email"
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
          required
        />
        <div className="relative">
          <input
            type={passwordType}
            name=""
            id="password"
            className="p-2 text-sm border border-gray-200 rounded-md outline-none my-2 w-full"
            placeholder="Password"
            value={user.password}
            onChange={(e) => setUser({ ...user, password: e.target.value })}
            required
          />
          {passwordType === 'password' ? (
            <span
              className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"
              onClick={changePasswordType}
            >
              <i className="fa-regular fa-eye-slash"></i>
            </span>
          ) : (
            <span
              className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"
              onClick={changePasswordType}
            >
              <i className="fa-regular fa-eye"></i>
            </span>
          )}
        </div>
        <p className="my-3 text-sm font-medium hover:underline cursor-pointer">
          Having trouble in sign in?
        </p>
        {error && (
          <p className="my-2 font-semibold text-center text-red-600 text-sm bg-red-200 rounded-lg p-1">
            Email or password incorrect !!! try again
          </p>
        )}
        <button
          className="w-full mt-2 py-2 text-center rounded-md text-sm bg-[#fdc886] hover:bg-[#f8c280] transition-all font-bold"
          onClick={handleSubmit}
        >
          Sign in
        </button>
        <p className="text-center text-sm mt-4">
          Sponsor by Golden Owl Consulting{' '}
          <i className="fa-solid fa-copyright"></i>
        </p>
      </form>
    </div>
  );
};

export default Login;
