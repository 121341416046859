import Header from './components/Header';

function HeaderLayout({ children }) {
  return (
    <div className="wrapper">
      <main>
        <Header name="back" />
        <div className="overflow-auto h-[92vh]">{children}</div>
      </main>
    </div>
  );
}

export default HeaderLayout;
