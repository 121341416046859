import { useState } from 'react';

const QuizItem = ({ quiz, quizId }) => {
  const [result, setResult] = useState(false);
  const [showResult, setShowResult] = useState(false);
  return (
    <div>
      <div>
        #{quizId + 1}.
        <input
          type="text"
          value={quiz.question}
          className="input mx-2 border rounded w-3/4 p-2 font-semibold"
          disabled
        />
        {quiz.answers.map((answer, answerId) => {
          return (
            <div key={answerId} className="my-3.5 flex items-center">
              <input
                id={`${quizId}-${answerId}`}
                type="radio"
                name={'quiz' + quizId}
                className="w-3.5 h-3.5 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                onChange={() => {
                  setResult(answer.isCorrect);
                  setShowResult(false);
                }}
              />
              <label
                htmlFor={`${quizId}-${answerId}`}
                className="cursor-pointer mx-2 border rounded w-1/2 p-2"
              >
                {answer.text}
              </label>
            </div>
          );
        })}
      </div>
      <div className="flex items-center">
        <button
          className="text-blue-500 bg-blue-100 rounded p-0.5 hover:bg-blue-200"
          onClick={() => setShowResult(true)}
        >
          Check
        </button>
        {showResult && (
          <div className="mx-3">
            {result ? (
              <span className="mx-3 text-green-500 font-bold">True</span>
            ) : (
              <span className="mx-3 text-red-500 font-bold">False</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizItem;
