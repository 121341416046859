import Header from './components/Header';
import Sidebar from './components/Sidebar';

function DefaultLayout({ children }) {
  return (
    <div className="wrapper">
      <Sidebar />
      <main className="w-[84%] fixed top-0 bottom-0 right-0">
        <Header name="default" />
        <div className="content mt-2 px-5 h-[92vh] overflow-y-scroll">
          {children}
        </div>
      </main>
    </div>
  );
}

export default DefaultLayout;
