import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Team } from '@/constants';
import { useCheck } from '@/hooks';
import { userService } from '@/services';

function ListUser() {
  const can = useCheck();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getUser = async () => {
      let { data } = await userService.all();
      setUsers(data.users);
    };
    getUser();
  }, []);

  const deleteUser = async (id) => {
    let check = window.confirm('Bạn có muốn xóa user này?');
    if (check) {
      await userService.delete(id);
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
    }
  };
  return (
    <div className="user">
      <div className="my-3 flex justify-start">
        {can('user_create') && (
          <Link
            to="/admin/user/create"
            className="px-2 py-1 rounded-lg text-gray-900 bg-gray-200 font-semibold hover:bg-gray-300 transition-all"
          >
            <span className="mr-2">
              <i className="fa-solid fa-circle-plus"></i>
            </span>
            Create User
          </Link>
        )}
      </div>
      <table className="table divide-y rounded-lg w-full overflow-x-auto">
        <thead className="bg-gray-50">
          <tr className="whitespace-nowrap text-left bg-gray-100">
            <th scope="col" className="py-3 px-2 text-sm text-gray-500">
              Id
            </th>
            <th scope="col" className="py-3 px-2 text-sm text-gray-500">
              Username
            </th>
            <th scope="col" className="py-3 px-2 text-sm text-gray-500">
              Email
            </th>
            <th scope="col" className="py-3 px-2 text-sm text-gray-500">
              Team
            </th>
            <th scope="col" className="py-3 px-2 text-sm text-gray-500">
              Role
            </th>
            <th scope="col" className="py-3 px-2 text-sm text-gray-500">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y">
          {users.map((user, index) => {
            return (
              <tr key={index} className="whitespace-nowrap">
                <td className="py-4 px-2 text-sm font-semibold">#{user.id}</td>
                <td className="py-4 px-2 text-sm">{user.full_name}</td>
                <td className="py-4 px-2 text-sm">{user.email}</td>
                <td className="py-4 px-2 text-sm">
                  <span
                    className={`${Team[user.team?.toLowerCase()]} p-1 rounded`}
                  >
                    {user.team}
                  </span>
                </td>
                <td className="py-4 px-2 text-sm">
                  {user.roles.map((role, index) => (
                    <span
                      key={index}
                      className="mx-1 border p-1 rounded font-semibold"
                    >
                      {role.name}
                    </span>
                  ))}
                </td>
                <td className="py-4 px-2">
                  <div className="flex items-center">
                    {can('user_delete') && (
                      <button
                        className="mx-2 outline-none px-3 py-1 text-sm rounded-full font-semibold text-red-500 bg-red-200"
                        onClick={() => deleteUser(user.id)}
                      >
                        delete
                      </button>
                    )}
                    {can('user_edit') && (
                      <Link
                        to={`/admin/user/edit/${user.id}`}
                        className="outline-none px-3 py-1 text-sm rounded-full text-blue-600 bg-blue-200"
                      >
                        edit
                      </Link>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ListUser;
