import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Editor from '@/components/Editor';
import MarkDown from '@/components/MarkDown';
import { useCheck } from '@/hooks';
import { courseService, lessonService } from '@/services';

import Counter from './components/Counter';
import Introduce from './components/Introduce';
import LessonItem from './components/LessonItem';
import Quiz from './components/Quiz';

const Detail = () => {
  const { id } = useParams();
  const can = useCheck();
  const navigate = useNavigate();
  const [currentLessonInfo, setCurrentLessonInfo] = useState({
    data: '',
    code: '',
    number: 0,
    current: 0,
    next: 0,
    duration: ''
  });
  const [course, setCourse] = useState({
    title: '',
    lessons: []
  });
  const [reload, setReload] = useState(false);
  const [tracking, setTracking] = useState(false);
  const [counter, setCounter] = useState(0);
  const markDownElement = useRef(null);
  const editorRef = useRef(null);

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
  }

  const handleChangeLesson = async (lessonId, lessonStatus) => {
    if (can('course_edit')) {
      switchLesson(lessonId);
      return;
    }
    if (course.status === 'Unsubcribe') {
      toast.warn('You need to register course first!!!', {
        autoClose: 1000,
        position: 'top-left'
      });
      return;
    }

    if (lessonStatus === 'locked') {
      toast.warn('You do not have permisson to this lesson!!!', {
        autoClose: 1000,
        position: 'top-left'
      });
      return;
    }
    switchLesson(lessonId);
  };

  const switchLesson = (lessonId, currentLessonId) => {
    let newLessons = course.lessons.map((lesson, index, arr) => {
      if (lesson.id === lessonId) {
        lesson = {
          ...lesson,
          result: currentLessonId
            ? { ...lesson.result, status: 'learning' }
            : { ...lesson.result },
          active: true
        };
        setCurrentLessonInfo({
          data: lesson.data,
          code: lesson.result?.code,
          assignment_type: lesson.assignment_type,
          language: lesson.language,
          code_practice: lesson.code_practice,
          quizzes: lesson.quizzes,
          number: index + 1,
          current: lesson.id,
          next: arr[index + 1]?.id,
          duration: lesson.duration,
          status: currentLessonId ? 'learning' : lesson.result?.status
        });
      } else if (lesson.id === currentLessonId) {
        lesson = {
          ...lesson,
          result: { ...lesson.result, status: 'submitted' },
          active: false
        };
      } else {
        lesson = { ...lesson, active: false };
      }
      return lesson;
    });

    setCourse({
      ...course,
      current_lesson: currentLessonId
        ? course.current_lesson + 1
        : course.current_lesson,
      lessons: [...newLessons]
    });
  };

  const nextToLockLesson = async (currentLessonId, nextLessonId) => {
    if (!tracking) {
      toast.warn('You learn so quickly!!! Calm down my friend', {
        position: 'top-left',
        autoClose: 1000
      });
      return;
    }
    await lessonService.changeLesson({
      lessonId: currentLessonId,
      nextLessonId: nextLessonId,
      courseId: course.id,
      code: editorRef.current?.getValue()
    });
    toast.success('Submit assignment successfully! Waiting for mentor judge', {
      position: 'top-left',
      autoClose: 1000
    });
    switchLesson(nextLessonId, currentLessonId);
  };

  const updateCurrentAssignment = async (currentLessonId) => {
    await lessonService.updateAssignment({
      lessonId: currentLessonId,
      courseId: course.id,
      code: editorRef.current?.getValue()
    });
    toast.success('Update successfully!!!', {
      position: 'top-left',
      autoClose: 1000
    });
  };

  const receiveCertificate = async (lessonId) => {
    await lessonService.receiveCertificate(course.id, {
      lessonId,
      code: editorRef.current.getValue()
    });
    toast.success('Congratulation <3. Waiting for metor judge assignment.', {
      position: 'top-left',
      autoClose: 1000
    });
  };

  useEffect(() => {
    const findCourse = async () => {
      let { data } = await courseService.find(id);
      setCourse(data.course);
    };
    findCourse();
  }, [id, reload]);

  const { percent, overview } = useMemo(() => {
    return {
      percent: Math.floor(
        ((course.current_lesson || 0) / course.lessons?.length) * 100
      ),
      overview: `${course.current_lesson || 0}/${course.lessons?.length}`
    };
  }, [course]);

  const scrollToTop = () => {
    if (markDownElement.current) {
      markDownElement.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    scrollToTop();
    // convert duration to seconds
    if (currentLessonInfo.status === 'learning') {
      setTracking(false);
      let duration = Math.floor(
        currentLessonInfo.duration.substring(0, 2) * 60 * 1000 * 0.4
      );
      setCounter(duration);
      let id = setTimeout(() => {
        setTracking(true);
      }, duration);
      return () => {
        clearTimeout(id);
      };
    }
  }, [currentLessonInfo]);

  return (
    <div className="detail">
      <nav className="fixed top-0 right-0 left-0 flex justify-between items-center bg-[#29303b] h-[50px] text-white">
        <div className="ml-3">
          <button onClick={() => navigate(-1)}>
            <i className="fa-solid fa-chevron-left"></i>
          </button>
          <span className="ml-5 font-semibold">{course.title}</span>
        </div>
        <div className="text-sm">
          {course.lessonLength > 0 &&
            currentLessonInfo.data !== '' &&
            currentLessonInfo.current === course.last_lesson &&
            course.study?.status !== 'completed' &&
            !can('course_edit') && (
              <button
                onClick={() => receiveCertificate(currentLessonInfo.current)}
                className="btn-main mt-0 ml-2 font-semibold"
              >
                Recieve Certificate
                <span className="text-sm ml-2 relative top-[1px]">
                  <i className="fa-solid fa-certificate"></i>
                </span>
              </button>
            )}
          <span className="mx-2 inline-block p-1 rounded-full border">
            {percent}%
          </span>
          <span className="mx-2">
            <span className="font-semibold mr-1">{overview}</span>
            bài học
          </span>
        </div>
      </nav>
      <div className="content">
        <div
          className="main fixed w-[76%] top-[50px] left-0 bottom-0 overflow-y-scroll"
          ref={markDownElement}
        >
          {course.lessonLength > 0 && currentLessonInfo.data !== '' && (
            <div className="custom-html-style pb-10 w-3/4 text-justify mx-auto">
              <MarkDown source={currentLessonInfo.data} />
              <hr></hr>
              <div>
                {currentLessonInfo.assignment_type === 'code_practice' && (
                  <>
                    <p className="my-2">
                      <span className="font-medium">Assignment:</span>{' '}
                      {currentLessonInfo.code_practice}
                    </p>
                    <Editor
                      onMount={handleEditorDidMount}
                      code={currentLessonInfo.code}
                      defaultLanguage={currentLessonInfo.language}
                    />
                  </>
                )}
                {currentLessonInfo.assignment_type === 'quiz' && (
                  <Quiz quizzes={currentLessonInfo.quizzes} />
                )}
              </div>
              <div className="flex justify-end mt-5">
                {currentLessonInfo.current < course.last_lesson &&
                  currentLessonInfo.number === course.current_lesson && (
                    <div
                      className="btn-main flex items-center cursor-pointer"
                      onClick={() =>
                        nextToLockLesson(
                          currentLessonInfo.current,
                          currentLessonInfo.next
                        )
                      }
                    >
                      <Counter seconds={counter} />
                      Next Lesson
                      <span className="text-sm ml-2 relative top-[1px]">
                        <i className="fa-solid fa-chevron-right"></i>
                      </span>
                    </div>
                  )}

                {currentLessonInfo.number < course.current_lesson && (
                  <button
                    className="btn-main"
                    onClick={() =>
                      updateCurrentAssignment(currentLessonInfo.current)
                    }
                  >
                    Update Assignment
                    <span className="text-sm ml-2 relative top-[1px]">
                      <i className="fa-solid fa-chevron-right"></i>
                    </span>
                  </button>
                )}
              </div>
            </div>
          )}
          {currentLessonInfo.data === '' && course.status === 'Subcribe' && (
            <div className="flex flex-col items-center justify-center h-[80vh]">
              <span className="text-5xl">
                <i className="fa-solid fa-person-chalkboard"></i>
              </span>
              <h1 className="mt-2 text-blue-500">
                Select Lesson and Start Learning.
              </h1>
              <span className="text-gray-500 mt-2">
                Make everything greater and greater ♥
              </span>
            </div>
          )}
          {course.status === 'Unsubcribe' && !can('course_edit') && (
            <Introduce course={course} reload={reload} setReload={setReload} />
          )}
        </div>
        <div className="sidebar fixed w-[24%] top-[50px] right-0 bottom-0 bg-white border-l-2">
          <h2 className="border-b-2 p-2">Course Content</h2>
          {can('course_create') && (
            <Link
              to={`/admin/lesson/create/${id}`}
              className="inline-block m-2 text-main border-2 border-main rounded-md px-2 hover:bg-main hover:text-white transition-all"
            >
              Create new Lesson
            </Link>
          )}

          <div className="lesson divide-y-2 select-none overflow-y-auto h-[calc(100vh-140px)]">
            {course.lessons.map((lesson, index, arr) => {
              return (
                <LessonItem
                  key={index}
                  lesson={lesson}
                  active={lesson.active}
                  onChangeLesson={handleChangeLesson}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
