import MonacoEditor from '@monaco-editor/react';
import { useState } from 'react';

function Editor({ onMount, code, defaultLanguage }) {
  const [language, setLanguage] = useState(defaultLanguage);
  return (
    <>
      <div className="flex justify-end">
        <select
          name=""
          id=""
          className="border-2 rounded"
          onChange={(e) => setLanguage(e.target.value)}
        >
          <option value="javascript">Javascript</option>
          <option value="html">HTML</option>
          <option value="css">CSS</option>
          <option value="ruby">Ruby</option>
          <option value="php">PHP</option>
          <option value="python">Python</option>
          <option value="c">C</option>
          <option value="c++">C++</option>
          <option value="c#">C#</option>
          <option value="java">Java</option>
        </select>
      </div>
      <div className="mt-3 py-3 bg-[#1e1e1e] text-base">
        <MonacoEditor
          options={{
            fontSize: '17px'
          }}
          height="50vh"
          defaultLanguage={language}
          defaultValue={
            code
              ? code
              : `function solve() {
  
}
          `
          }
          theme="vs-dark"
          path={code + language + Math.random()}
          onMount={onMount}
        />
      </div>
    </>
  );
}

export default Editor;
