import { memo, useState } from 'react';
import { toast } from 'react-toastify';

function SkillInput({ name, skills, onAddSkill }) {
  const [filterValue, setFilterValue] = useState('');
  const [level, setLevel] = useState({
    id: 0,
    number: 0
  });
  const handleChooseSkill = (e, skill) => {
    e.preventDefault();
    if (skill.id !== level.id) {
      toast.error('Invalid skills!!!', {
        position: 'top-left',
        autoClose: 1000
      });
      return;
    }
    onAddSkill(skill, name.toLowerCase(), level.number);
    setFilterValue('');
  };
  return (
    <div className="flex items-center">
      <label htmlFor={name} className="font-semibold min-w-[100px]">
        {name}
      </label>
      <div className="mt-2 flex items-center border px-2 w-[250px] relative">
        <input
          type="text"
          className="outline-none py-1 text-base rounded w-full"
          id={name}
          placeholder={name}
          autoComplete="off"
          onChange={(e) => setFilterValue(e.target.value)}
          value={filterValue}
        />
        <span>
          <i className="fa-solid fa-angle-down"></i>
        </span>
        {filterValue.trim().length > 0 && (
          <div className="absolute top-full left-0 right-0 bg-gray-100 z-10">
            <ul className="w-full h-[200px] text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 overflow-auto">
              {skills
                .filter(({ name }) =>
                  name.toLowerCase().includes(filterValue.toLowerCase())
                )
                .map((skill, index) => {
                  return (
                    <li
                      key={index}
                      className="p-2 w-full border-gray-200 hover:bg-gray-200"
                    >
                      <div className="flex items-center justify-between">
                        <img
                          src={skill.image}
                          className="h-6 object-cover rounded flex-1"
                          alt=""
                        />
                        <select
                          className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 mx-2 p-0.5"
                          defaultValue="0"
                          onChange={(e) =>
                            setLevel({ id: skill.id, number: e.target.value })
                          }
                        >
                          <option value="0">Level</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </select>
                        <button
                          onClick={(e) => handleChooseSkill(e, skill)}
                          className="btn-main px-1.5 py-0.5 mt-0"
                        >
                          <i className="fa-solid fa-plus"></i>
                        </button>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(SkillInput);
