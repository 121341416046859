import { Link } from 'react-router-dom';

function EmptyCourse({ title, description }) {
  return (
    <div className="flex flex-col items-center">
      <span className="text-4xl px-3 py-2 border border-black rounded-full">
        <i className="fa-solid fa-bucket"></i>
      </span>
      <h1 className="text-3xl my-2 font-light">{title}</h1>
      <p className="text-sm my-2">{description}</p>
      <Link
        to="/"
        className="text-blue-500 hover:text-blue-600 text-sm font-semibold"
      >
        Learn your first course
      </Link>
    </div>
  );
}

export default EmptyCourse;
