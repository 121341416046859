function SkillItem({ image, level }) {
  return (
    <div className="mr-4 mt-4 relative">
      <img src={image} className="h-7 w-[120px] object-cover rounded" alt="" />
      {level && (
        <div className="absolute -top-3 left-1/2 -translate-x-1/2 flex items-center text-gray-500 text-[8px]">
          {[...Array(level * 1)].map((level, index) => (
            <i key={index} className="mx-1 fa-solid fa-star"></i>
          ))}
          {[...Array(3 - level * 1)].map((level, index) => (
            <i key={index} className="mx-1 fa-regular fa-star"></i>
          ))}
        </div>
      )}
    </div>
  );
}

export default SkillItem;
