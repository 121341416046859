import images from '@/assets/images';

const pointDecision = (point, status) => {
  point = point * 1;
  if (point === 0) {
    return {
      point,
      badge: '',
      status
    };
  } else if (point <= 5) {
    return {
      point,
      badge: '',
      status: 'retake'
    };
  } else if (point <= 7) {
    return {
      point,
      badge: images.trophy1,
      status: 'completed'
    };
  } else if (point <= 9) {
    return {
      point,
      badge: images.trophy3,
      status: 'completed'
    };
  } else {
    return {
      point,
      badge: images.trophy5,
      status: 'completed'
    };
  }
};

export { pointDecision };
