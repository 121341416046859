import HeaderLayout from '@/layouts/HeaderLayout';
import NullLayout from '@/layouts/NullLayout';
import UserCourse from '@/pages/admin/classes/UserCourse';
import UserLesson from '@/pages/admin/classes/UserLesson';
import ListCourse from '@/pages/admin/courses';
import CreateCourse from '@/pages/admin/courses/Create';
import CreateLesson from '@/pages/admin/lessons/Create';
import ListRole from '@/pages/admin/roles';
import CreateRole from '@/pages/admin/roles/Create';
import ListUser from '@/pages/admin/users';
import CreateUser from '@/pages/admin/users/Create';
import Detail from '@/pages/detail';
import Home from '@/pages/home';
import Login from '@/pages/Login';
import MyClass from '@/pages/MyClass';
import Profile from '@/pages/profile';
import EditProfile from '@/pages/profile/EditProfile';
import Rank from '@/pages/rank';

const privateRoutes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/detail/:id',
    component: Detail,
    layout: NullLayout
  },
  {
    path: '/myclass',
    component: MyClass
  },
  {
    path: '/admin/lesson/create/:courseId',
    component: CreateLesson,
    layout: HeaderLayout
  },
  {
    path: '/admin/lesson/edit/:lessonId',
    component: CreateLesson,
    layout: HeaderLayout
  },
  {
    path: '/admin/lesson/user-lesson',
    component: UserLesson
  },
  {
    path: '/admin/course',
    component: ListCourse
  },
  {
    path: '/admin/course/create',
    component: CreateCourse
  },
  {
    path: '/profile/:id',
    component: Profile,
    layout: HeaderLayout
  },
  {
    path: '/edit-profile/:id',
    component: EditProfile,
    layout: HeaderLayout
  },
  {
    path: '/admin/user',
    component: ListUser
  },
  {
    path: '/admin/user/create',
    component: CreateUser
  },
  {
    path: '/admin/user/edit/:id',
    component: CreateUser
  },
  {
    path: '/admin/user/user-course/:id',
    component: UserCourse
  },
  {
    path: '/admin/role',
    component: ListRole
  },
  {
    path: '/admin/role/create',
    component: CreateRole
  },
  {
    path: '/rank',
    component: Rank
  }
];

const publicRoutes = [
  {
    path: '/login',
    component: Login,
    layout: NullLayout
  }
];

export { privateRoutes, publicRoutes };
