import SkillItem from '@/components/SkillItem';
import { courseService } from '@/services';

const Introduce = ({ course, reload, setReload }) => {
  const enrollCourse = async (courseId) => {
    await courseService.enrollCourse(courseId);
    setReload(!reload);
  };
  return (
    <div className="w-3/4 mx-auto mt-10">
      <div className="my-4">
        <h1 className="text-black text-3xl font-bold">{course.title}</h1>
        <p className="text-gray-600 mt-2">{course.description}</p>
      </div>
      <div className="my-5">
        <h2 className="text-black font-bold">Course Content</h2>
        <ul className="list-disc flex mt-2">
          <li className="mx-4">{course.lessonLength} Lessons</li>
          <li className="mx-4">Duration {course.duration}</li>
        </ul>
      </div>
      <div className="my-5">
        <h2 className="text-black font-bold">
          Skills will be achieved after completing this course
        </h2>
        <p className="mt-4">
          <span className="mr-2">
            <i className="fa-solid fa-language"></i>
          </span>
          Languages
        </p>
        <div className="flex flex-wrap text-white">
          {course.languages.map((skill, index) => {
            return (
              <SkillItem key={index} image={skill.image} level={skill.level} />
            );
          })}
        </div>
        <p className="mt-4">
          <span className="mr-2">
            <i className="fa-solid fa-toolbox"></i>
          </span>
          Tools
        </p>
        <div className="flex flex-wrap text-white">
          {course.tools.map((skill, index) => {
            return (
              <SkillItem key={index} image={skill.image} level={skill.level} />
            );
          })}
        </div>
      </div>
      <div className="my-5">
        <h2 className="text-black font-bold">Requirements</h2>
        <ul className="list-disc">
          <li className="mx-4 my-2">Computer with internet connection</li>
          <li className="mx-4 my-2">
            Self-learning, working hard, ready to face difficult problem
          </li>
          <li className="mx-4 my-2">Feel free when start learning lesson</li>
          <li className="mx-4 my-2">Ready to start now</li>
        </ul>
      </div>
      <div className="my-5">
        <button
          onClick={() => enrollCourse(course.id)}
          className="px-10 py-2 bg-blue-100 text-[#5350f7] hover:bg-[#5350f7] hover:text-white font-semibold text-sm rounded-full transition-all"
        >
          <span className="mx-2">
            <i className="fa-solid fa-lock"></i>
          </span>
          Enroll Now
        </button>
      </div>
    </div>
  );
};
export default Introduce;
