import QuizItem from './QuizItem';

const Quiz = ({ quizzes }) => {
  return (
    <div className="quizzes space-y-4">
      {quizzes.map((quiz, index) => (
        <QuizItem key={index} quiz={quiz} quizId={index} />
      ))}
    </div>
  );
};

export default Quiz;
