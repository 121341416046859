import { Link } from 'react-router-dom';

import { Team } from '@/constants';

const ListUser = ({ users }) => {
  return (
    <table className="table divide-y rounded-lg w-full overflow-x-auto mt-2">
      <thead className="bg-gray-50">
        <tr className="whitespace-nowrap text-left bg-gray-100">
          <th scope="col" className="py-3 px-2 text-sm text-gray-500">
            UserName
          </th>
          <th
            scope="col"
            className="py-3 px-2 text-sm text-gray-500 cursor-pointer"
          >
            Rank
          </th>
          <th scope="col" className="py-3 px-2 text-sm text-gray-500">
            Badge
          </th>
          <th
            scope="col"
            className="py-3 px-2 text-sm text-gray-500 cursor-pointer"
          >
            Point
          </th>
          <th
            scope="col"
            className="py-3 px-2 text-sm text-gray-500 cursor-pointer"
          >
            Team
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y">
        {users.map((user, index) => {
          return (
            <tr key={index} className="whitespace-nowrap">
              <td className="py-4 px-2 font-semibold hover:text-blue-500 hover:underline">
                <Link to={`/profile/${user.id}`}>@{user.full_name}</Link>
              </td>
              <td className="py-4 px-2 text-lg">#{user.rank.id}</td>
              <td className="py-4 px-2">
                <img
                  src={user.rank.badge}
                  className="h-16 w-1/2 object-fill"
                  alt=""
                />
              </td>
              <td className="py-4 px-2">
                <span className="mr-2 text-sm text-yellow-500">
                  <i className="fa-solid fa-coins"></i>
                </span>
                {user.point}
              </td>
              <td className="py-4 px-2">
                <span
                  className={`px-2 py-1 text-sm font-semibold rounded ${
                    Team[user.team.toLowerCase()]
                  }`}
                >
                  {user.team}
                </span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ListUser;
