const { StorageKey } = require('@/constants');

const getAuthUser = () => {
  try {
    return JSON.parse(localStorage.getItem(StorageKey.authUser));
  } catch (error) {
    console.error(error);
    return null;
  }
};

const setAuthUser = (user) => {
  localStorage.setItem(StorageKey.authUser, JSON.stringify(user));
};

const removeAuthUser = () => {
  localStorage.removeItem(StorageKey.authUser);
};

const getAuthToken = () => {
  try {
    return JSON.parse(localStorage.getItem(StorageKey.authToken));
  } catch (error) {
    console.error(error);
    return null;
  }
};

const setAuthToken = (token) => {
  localStorage.setItem(StorageKey.authToken, JSON.stringify(token));
};

const removeAuthToken = () => {
  localStorage.removeItem(StorageKey.authToken);
};

export {
  getAuthToken,
  getAuthUser,
  removeAuthToken,
  removeAuthUser,
  setAuthToken,
  setAuthUser
};
