const LessonBadge = ({ image, point }) => {
  return (
    <div className="flex items-center mx-3">
      <img src={image} alt="" className="h-6 object-cover mr-1" />
      <span className="mx-2">{point}</span>
    </div>
  );
};

export default LessonBadge;
