import { useEffect, useState } from 'react';

const Counter = ({ seconds }) => {
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    setCounter((prev) => seconds / 1000);
    const id = setInterval(() => {
      setCounter((prev) => {
        if (prev === 0) {
          clearInterval(id);
        } else {
          prev -= 1;
        }
        return prev;
      });
    }, 1000);
    return () => clearInterval(id);
  }, [seconds]);
  return (
    <div className="w-[52px] text-blue-500 text-sm">
      <i className="fa-regular fa-clock"></i> {counter}s
    </div>
  );
}

export default Counter;
