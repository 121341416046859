import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Table from '@/components/Table';
import { Team } from '@/constants';
import { courseService } from '@/services';

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    render: (text) => <span className="font-semibold">#{text + 1}</span>
  },
  {
    title: 'Title',
    dataIndex: 'title',
    render: (_, record) => (
      <div className="flex items-center ">
        <img
          src={record.image}
          alt=""
          className="w-[120px] h-[80px] object-cover rounded"
        />
        <span className="ml-2 text-ellipsis overflow-hidden whitespace-nowrap">
          {record.title}
        </span>
      </div>
    )
  },
  {
    title: 'Duration',
    dataIndex: 'duration'
  },
  {
    title: 'Category',
    dataIndex: 'category'
  },
  {
    title: 'Student',
    dataIndex: 'intern'
  },
  {
    title: 'Team',
    dataIndex: 'team',
    render: (text) => (
      <span
        className={`px-2 py-1 text-sm font-semibold rounded ${
          Team[text.toLowerCase()]
        }`}
      >
        {text}
      </span>
    )
  },
  {
    title: 'Instractor',
    dataIndex: 'instractor'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: () => (
      <span className="bg-green-100 text-green-500 p-1 rounded-md">public</span>
    )
  },
  {
    title: 'Action',
    dataIndex: 'action',
    render: (_, record) => (
      <div className="flex items-center">
        <Link
          to={`/detail/${record.id}`}
          className="outline-none px-3 py-1 text-lg rounded-full font-semibold"
        >
          <i className="fa-solid fa-circle-plus"></i>
        </Link>
        <Link
          to={`/admin/user/user-course/${record.id}`}
          className="outline-none px-3 py-1 text-lg rounded-full font-semibold"
        >
          <i className="fa-solid fa-people-group"></i>
        </Link>
        <button className="mx-2 outline-none px-3 py-1 text-xs rounded-full font-semibold text-red-500 bg-red-200">
          delete
        </button>
        <Link className="outline-none px-3 py-1 text-xs rounded-full text-blue-600 bg-blue-200">
          edit
        </Link>
      </div>
    )
  }
];

const ListCourse = () => {
  const [courses, setCourses] = useState([]);
  useEffect(() => {
    const getAllCourses = async () => {
      let data = await courseService.coursesForAdmin();
      setCourses(data);
    };
    getAllCourses();
  }, []);

  return (
    <div className="voucher">
      <div className="my-4 flex justify-start">
        <Link to="/admin/course/create" className="btn-normal">
          <span className="mr-2">
            <i className="fa-solid fa-circle-plus"></i>
          </span>
          Create Course
        </Link>
      </div>
      <Table dataSource={courses || []} columns={columns} />
    </div>
  );
};

export default ListCourse;
