import request from '@/request';

const authService = {
  async login(credentials) {
    return await request.post('/auth/login', credentials);
  },
  async register(user) {
    return await request.post('/auth/register', user);
  },
  async logout() {
    return await request.post('/auth/logout');
  }
};

export { authService };
