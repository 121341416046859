import '@/assets/css/index.css';
import '@/assets/css/override.css';
import 'react-toastify/dist/ReactToastify.css';

import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import PrivateRoute from '@/components/PrivateRoute';
import ScrollToTop from '@/components/ScrollToTop';
import DefaultLayout from '@/layouts/DefaultLayout';
import { privateRoutes, publicRoutes } from '@/routes';
import { persistor, store } from '@/store';

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App">
          <Router>
            <ScrollToTop />
            <ToastContainer />
            <Routes>
              {publicRoutes.map((route, index) => {
                const Page = route.component;
                const Layout = route.layout || DefaultLayout;
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <Layout>
                        <Page />
                      </Layout>
                    }
                  />
                );
              })}
              {privateRoutes.map((route, index) => {
                const Page = route.component;
                const Layout = route.layout || DefaultLayout;
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <PrivateRoute>
                        <Layout>
                          <Page />
                        </Layout>
                      </PrivateRoute>
                    }
                  />
                );
              })}
            </Routes>
          </Router>
        </div>
      </PersistGate>
    </Provider>
  );
};

export default App;
