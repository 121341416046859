function CodeSection({ lesson, setLesson }) {
  return (
    <>
      <div className="mb-4 flex items-center">
        <label htmlFor="" className="font-semibold inline-block min-w-[100px]">
          Language
        </label>
        <select
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2"
          required
          onChange={(e) => setLesson({ ...lesson, language: e.target.value })}
          value={lesson.language}
        >
          <option value="0">Choose a Language</option>
          <option value="html">HTML</option>
          <option value="css">CSS</option>
          <option value="javascript">Javascript</option>
          <option value="php">PHP</option>
          <option value="ruby">Ruby</option>
          <option value="golang">GoLang</option>
        </select>
      </div>
      <div className="mb-4">
        <label
          htmlFor="title"
          className="inline-block font-semibold mb-2 text-gray-900 "
        >
          Assignment
        </label>
        <textarea
          rows={7}
          type="title"
          id="title"
          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          placeholder="Write assignment here"
          required
          onChange={(e) =>
            setLesson({ ...lesson, code_practice: e.target.value })
          }
          value={lesson.code_practice}
        />
      </div>
    </>
  );
}

export default CodeSection;
