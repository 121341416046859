import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import Table from '@/components/Table';
import { courseService, userService } from '@/services';

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    render: (text) => <span className="font-semibold">#{text + 1}</span>
  },
  {
    title: 'FullName',
    dataIndex: 'full_name',
    render: (text, record) => (
      <div className="flex items-center">
        <img
          src={record.image}
          alt=""
          className="w-10 h-10 object-cover rounded-full"
        />
        <span className="mx-2">{text}</span>
      </div>
    )
  },
  {
    title: 'Email',
    dataIndex: 'email'
  },
  {
    title: 'Team',
    dataIndex: 'team',
    render: (text) => <span className="font-semibold">{text}</span>
  },
  {
    title: 'Position',
    dataIndex: 'position'
  },
  {
    title: 'Action',
    dataIndex: 'action',
    render: (_, record) => (
      <Link
        to={`/admin/lesson/user-lesson?userId=${record.id}&courseId=${
          useParams().id
        }`}
        className="px-3 py-1 text-2xl rounded-full font-semibold"
      >
        <i className="fa-solid fa-list-check"></i>
      </Link>
    )
  }
];

const UserCourse = () => {
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [course, setCourse] = useState(null);
  useEffect(() => {
    const getUser = async () => {
      const data = await userService.getUserByCourseId(id);
      setUsers(data);
    };

    const getCourse = async () => {
      let { data } = await courseService.find(id);
      setCourse(data.course);
    };
    getUser();
    getCourse();
  }, [id]);

  return (
    <div className="user">
      <div className="my-3 flex justify-start">
        <Link
          to={'/admin/course'}
          className="px-2 py-1 rounded-lg text-gray-900 bg-gray-200 font-semibold hover:bg-gray-300 transition-all"
        >
          <span className="mr-2">
            <i className="fa-solid fa-chevron-left"></i>
          </span>
          Back to Courses
        </Link>
      </div>
      <div className="my-3 text-center">
        <span className="text-blue-500 font-bold text-xl">{course?.title}</span>
        <span className="mx-2">- Mentor: {course?.instractor}</span>
      </div>
      <Table dataSource={users} columns={columns} />
    </div>
  );
};

export default UserCourse;
