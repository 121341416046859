import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import HighLight from '@/components/HightLight';
import Modal from '@/components/Modal';
import { LessonStatus } from '@/constants';
import { lessonService } from '@/services';
import { pointDecision } from '@/utils/pointUtil';

const UserLesson = () => {
  const [searchParams] = useSearchParams();
  const [lessons, setLessons] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [code, setCode] = useState('');
  useEffect(() => {
    const getUser = async () => {
      let { data } = await lessonService.getLessonByCourseIdAndUserId({
        userId: searchParams.get('userId'),
        courseId: searchParams.get('courseId')
      });
      setLessons(data.lessons);
    };
    getUser();
  }, []);

  const showCode = (index) => {
    let codeString = lessons[index].pivot.code;
    if (codeString) {
      setShowModal(true);
      setCode(codeString);
    } else {
      toast.warning('Nothing to show !!!', {
        position: 'top-right',
        autoClose: 1000
      });
    }
  };

  const changePoint = async (point, lessonId) => {
    await lessonService.updatePoint({
      ...pointDecision(point),
      lessonId,
      userId: searchParams.get('userId'),
      courseId: searchParams.get('courseId')
    });
    let newLessons = lessons.map((lesson, index) => {
      if (lesson.id === lessonId) {
        lesson = {
          ...lesson,
          pivot: {
            ...lesson.pivot,
            ...pointDecision(point)
          }
        };
      }
      return lesson;
    });
    setLessons(newLessons);
  };
  return (
    <div className="user">
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <HighLight code={code} />
      </Modal>
      <div className="my-3 flex justify-start">
        <Link
          to={`/admin/user/user-course/${searchParams.get('courseId')}`}
          className="px-2 py-1 rounded-lg text-gray-900 bg-gray-200 font-semibold hover:bg-gray-300 transition-all"
        >
          <span className="mr-2">
            <i className="fa-solid fa-chevron-left"></i>
          </span>
          Back to List User
        </Link>
      </div>
      <p className="text-red-500 my-2">
        *Score is only given at once for each lesson
      </p>
      <table className="table divide-y rounded-lg w-full overflow-x-auto">
        <thead className="bg-gray-50">
          <tr className="whitespace-nowrap text-left bg-gray-100">
            <th scope="col" className="py-3 px-2 text-sm text-gray-500">
              Title
            </th>
            <th scope="col" className="py-3 px-2 text-sm text-gray-500">
              Point
            </th>
            <th scope="col" className="py-3 px-2 text-sm text-gray-500">
              Type
            </th>
            <th scope="col" className="py-3 px-2 text-sm text-gray-500">
              Badges
            </th>
            <th scope="col" className="py-3 px-2 text-sm text-gray-500">
              Status
            </th>
            <th scope="col" className="py-3 px-2 text-sm text-gray-500">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y">
          {lessons.map((lesson, index) => {
            return (
              <tr key={index} className="whitespace-nowrap">
                <td className="py-4 px-2 text-sm">{lesson.title}</td>
                <td className="py-4 px-2">
                  <select
                    className="font-semibold bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2"
                    defaultValue={lesson.pivot.point}
                    onChange={(e) => changePoint(e.target.value, lesson.id)}
                    disabled={lesson.pivot.point > 5}
                  >
                    <option value="0">Point</option>
                    {[...Array(10)].map((value, index) => {
                      return (
                        <option key={index} value={index + 1}>
                          {index + 1} pts
                        </option>
                      );
                    })}
                  </select>
                </td>
                <td className="py-4 px-2 text-sm font-medium">
                  {lesson.assignment_type}
                </td>
                <td className="py-4 px-2 text-sm">
                  <img
                    src={pointDecision(lesson.pivot.point).badge}
                    alt=""
                    className="w-10 object-cover"
                  />
                </td>
                <td className="py-4 px-2 text-sm">
                  <span
                    className={`${
                      LessonStatus[
                        pointDecision(lesson.pivot.point, lesson.pivot.status)
                          .status
                      ]
                    }`}
                  >
                    {lesson.pivot.status}
                  </span>
                </td>
                <td className="py-4 px-2">
                  <button
                    onClick={() => showCode(index)}
                    className="px-3 py-1 text-2xl rounded-full font-semibold"
                  >
                    <i className="fa-solid fa-code"></i>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default UserLesson;
