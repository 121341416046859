import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import images from '@/assets/images';
import Course from '@/components/Course';
import EmptyCourse from '@/components/EmptyCourse';
import { courseService, userService } from '@/services';
import { getAuthUser } from '@/utils/localStorageUtil';

import LessonBadge from './components/LessonBadge';
import Skill from './components/Skill';

const Profile = () => {
  const { id } = useParams();
  const [user, setUser] = useState({
    avatar: '',
    full_name: '',
    point: 0,
    languages: [],
    tools: []
  });
  const me = useRef(getAuthUser());
  const [learningCourse, setLearningCourse] = useState([]);
  const [completedCourse, setCompletedCourse] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const getUserProfile = async () => {
      let { data } = await userService.find(id);
      setUser(data.user);
    };
    const getAllPendingCourses = async () => {
      let { data } = await courseService.learningCourse(id);
      setLearningCourse(
        data.courses.filter((course) => course.study?.status === 'learning')
      );
      setCompletedCourse(
        data.courses.filter((course) => course.study?.status === 'completed')
      );
    };

    getAllPendingCourses();
    getUserProfile();
  }, [id, reload]);

  const percentToNextRank = useMemo(() => {
    return Math.floor(
      ((user.point - user.rank?.min_score) /
        (user.next_rank?.min_score - user.rank?.min_score)) *
        100
    );
  }, [user]);

  return (
    <div className="profile bg-[#f5f6f7] min-h-[calc(100vh-57px)]">
      <div className="container mx-auto">
        <div className="grid grid-cols-12 gap-5 pt-4">
          <div className="col-span-4">
            <div className="flex justify-center items-center flex-col bg-white py-4 rounded relative">
              <img
                src={user.image}
                alt=""
                className="w-[120px] h-[120px] p-2 rounded-full object-cover"
              />
              <h3 className="my-1">{user.full_name}</h3>
              {user.username && (
                <p className="my-1 text-gray-500">{user.username}</p>
              )}
              {user.current_role && (
                <p className="my-1 font-semibold text-sm">
                  <span className="mr-2">
                    <i className="fa-solid fa-code"></i>
                  </span>
                  {user.current_role}
                </p>
              )}
              {id * 1 === me.current.id && (
                <Link
                  to={`/edit-profile/${id}`}
                  className="absolute top-3 right-3"
                >
                  <i className="fa-solid fa-pen-to-square"></i>
                </Link>
              )}
            </div>
            <div className="mt-4 bg-white p-5 rounded">
              {user.position && (
                <div className="mt-2">
                  <span className="inline-block min-w-[30px]">
                    <i className="fa-solid fa-signs-post"></i>
                  </span>
                  <span>{user.position}</span>
                </div>
              )}
              {user.number_experience && (
                <div className="mt-2">
                  <span className="inline-block min-w-[30px]">
                    <i className="fa-solid fa-star"></i>
                  </span>
                  <span>{user.number_experience} experiences</span>
                </div>
              )}
              <div className="mt-2">
                <span className="inline-block min-w-[30px]">
                  <i className="fa-solid fa-envelope"></i>
                </span>
                <span>{user.email}</span>
              </div>
              {user.phone && (
                <div className="mt-2">
                  <span className="inline-block min-w-[30px]">
                    <i className="fa-solid fa-phone"></i>
                  </span>
                  <span>{user.phone}</span>
                </div>
              )}
              <div className="mt-2">
                <p className="font-semibold my-3">
                  Rank -
                  <Link to="/rank" className="ml-1 text-main font-semibold">
                    Top #{user.rank?.id}
                  </Link>
                </p>
                <div className="flex flex-col items-center justify-center">
                  <div className="flex flex-col items-center justify-center">
                    <img
                      src={user.rank?.badge}
                      alt=""
                      className="w-[160px] object-cover"
                    />
                    <div className="mt-2">
                      <span className="font-medium">{user.rank?.name}</span> -
                      <span className="font-semibold text-xl mx-2">
                        {user.point}
                      </span>
                      Point
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="flex-1 items-center bg-gray-200 rounded-full my-2">
                      <div
                        className="bg-blue-600 text-xs font-medium text-blue-100 text-center leading-none rounded-full"
                        style={{
                          width: `${percentToNextRank}%`
                        }}
                      >
                        <span className="text-[10px]">
                          {percentToNextRank}%
                        </span>
                      </div>
                    </div>
                    <img
                      src={user.next_rank?.badge}
                      className="w-8 h-7 object-cover ml-2"
                      alt=""
                    />
                  </div>
                  <div className="flex items-center justify-between mt-4">
                    <LessonBadge image={images.trophy1} point={user.bronze} />
                    <LessonBadge image={images.trophy3} point={user.silver} />
                    <LessonBadge image={images.trophy5} point={user.gold} />
                  </div>
                </div>
                <p className="font-semibold my-3">Badges</p>
                {user.badges?.length > 0 ? (
                  <div className="grid grid-cols-12 gap-3">
                    {user.badges?.map((badge, index) => {
                      return (
                        <div key={index} className="col-span-4">
                          <div className="relative group">
                            <img
                              src={badge.image}
                              alt=""
                              className="w-full px-5"
                            />
                            <span
                              className="hidden group-hover:inline-block absolute bg-green-100 
                            rounded-md text-green-600 p-1 text-sm -top-2 -right-[75%] z-10"
                            >
                              {badge.name}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <EmptyCourse
                    title="Empty"
                    description="Learn some courses to gain badges for yourself"
                  />
                )}
              </div>
              {user.experiences > 0 && (
                <div className="mt-2">
                  <p className="font-semibold my-3">Experiences</p>
                  <div className="my-2">2019 - 2020: Golden Owl Consulting</div>
                  <div className="my-2">2019 - 2020: Golden Owl Consulting</div>
                </div>
              )}
            </div>
          </div>
          <div className="col-span-8">
            <Skill
              name="Languages"
              personSkills={user.languages}
              onReload={setReload}
              reload={reload}
              isMe={id * 1 === me.current.id}
            />
            <Skill
              name="Tools"
              personSkills={user.tools}
              onReload={setReload}
              reload={reload}
              isMe={id * 1 === me.current.id}
            />
            <div className="bg-white rounded  p-4">
              <h2>Learning Courses</h2>
              {learningCourse.length > 0 ? (
                <div className="grid grid-cols-3 gap-5 my-2">
                  {learningCourse.map((course, index) => {
                    return (
                      <div className="col-span-1" key={index}>
                        <Course course={course} />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <EmptyCourse
                  title="Empty"
                  description="When you learn a course, they will appear on you profile"
                />
              )}
              <h2 className="mt-2">Completed Courses</h2>
              {completedCourse.length > 0 ? (
                <div className="grid grid-cols-3 gap-5 my-2">
                  {completedCourse.map((course, index) => {
                    return (
                      <div className="col-span-1" key={index}>
                        <Course course={course} />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <EmptyCourse
                  title="Empty"
                  description="When you learn a course, they will appear on you profile"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
