import { Link } from 'react-router-dom';

import { IconStatus } from '@/constants';
import { useCheck } from '@/hooks';

const LessonItem = ({ lesson, active, onChangeLesson }) => {
  const can = useCheck();
  return (
    <div
      className={`outline-none w-full hover:bg-slate-200 px-3 py-1 transition-all cursor-pointer select-none ${
        active ? 'bg-blue-200' : ''
      } `}
      onClick={() => onChangeLesson(lesson.id, lesson.result?.status)}
    >
      <div className="flex justify-between items-center">
        <p className={`text-sm text-left ${active ? 'font-semibold' : ''}`}>
          {lesson.title}
        </p>
        <div>
          <span className="text-sm">
            <i className={`fa-solid ${IconStatus[lesson.result?.status]}`}></i>
          </span>
          {can('course_edit') && (
            <Link
              to={`/admin/lesson/edit/${lesson.id}`}
              className="text-sm ml-2"
            >
              <i className="fa-solid fa-pen-to-square"></i>
            </Link>
          )}
        </div>
      </div>
      <div className="flex items-center mt-1 text-sm">
        <span className="text-gray-500">
          <i className="fa-solid fa-clipboard-list"></i>
        </span>
        <span className="mx-2">{lesson.duration}</span>
        {lesson.result && JSON.stringify(lesson.result) !== '{}' && (
          <>
            {lesson.result.badge && (
              <img
                src={lesson.result.badge}
                alt=""
                className="h-8 w-8 object-cover mx-2"
              />
            )}
            <span className={`${[lesson.result.status]} text-xs mx-2`}>
              {lesson.result.status} - {lesson.result.point} pts
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default LessonItem;
