import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { roleService } from '@/services';

function CreateRole() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [role, setRole] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id) {
      await roleService.update(id, { role });
    } else {
      await roleService.create({ role });
    }
    navigate('/admin/role');
  };

  useEffect(() => {
    const getRole = async () => {
      let res = await roleService.find(id);
      setRole(res.data.role.name);
    };
    if (id) {
      getRole();
    }
  }, [id]);

  return (
    <div className="create-role">
      <Link to="/admin/role" className="inline-block btn-normal">
        <span className="mr-2">
          <i className="fa-solid fa-chevron-left"></i>
        </span>
        Back to form
      </Link>
      <h1 className="text-center font-semibold text-2xl">Create new Role</h1>
      <div className="flex justify-center">
        <form className="w-1/3 my-3" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Name
            </label>
            <input
              type="name"
              id="name"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder=""
              required
              value={role}
              onChange={(e) => setRole(e.target.value)}
            />
          </div>
          <button className="btn-main mt-5">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default CreateRole;
