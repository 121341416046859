function Input(props) {
  const {
    id,
    placeholder = '',
    label = '',
    name = '',
    type = 'text',
    register,
    ...rest
  } = props;

  return (
    <>
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-semibold text-gray-900 "
      >
        {label}
      </label>
      <input
        type={type}
        id={id}
        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
        placeholder={placeholder}
        required
        autoComplete="true"
        {...register(name)}
        {...rest}
      />
    </>
  );
}

export default Input;
