function Modal({ showModal, setShowModal, children }) {
  if (!showModal) {
    return null;
  }

  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <div className="modal-order flex items-center justify-center fixed top-0 right-0 bottom-0 left-0 z-10">
        <div
          className="animate-fade-in modal-overlay bg-black opacity-20 absolute w-full h-full"
          onClick={hideModal}
        ></div>
        <div className="animate-grow-up modal-main max-w-[50%] rounded-lg bg-white opacity-1 p-3 relative ">
          <div className="modal-header flex justify-between items-center pb-2">
            <span className="font-semibold">Modal</span>
            <button
              className="text-black text-xl cursor-pointer"
              onClick={hideModal}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="modal-body py-2 px-5 ">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
