import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import Input from '@/components/Input';
import Loading from '@/components/Loading';
import SkillItem from '@/components/SkillItem';
import { courseService, languageService, toolService } from '@/services';

import SkillInput from './components/SkillInput';

const CreateCourse = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, watch } = useForm();
  const [languages, setLanguages] = useState([]);
  const [tools, setTools] = useState([]);
  const [recieveSkill, setRecieveSkill] = useState({
    languages: [],
    tools: []
  });
  const [showLoading, setShowLoading] = useState(false);
  useEffect(() => {
    const getLanguages = async () => {
      let { data } = await languageService.all();
      setLanguages(data.languages);
    };
    const getTools = async () => {
      let { data } = await toolService.all();
      setTools(data.tools);
    };
    getLanguages();
    getTools();
  }, []);

  const addSkill = (skill, name, level) => {
    let check = recieveSkill[name].findIndex(({ id }) => id === skill.id);
    let newSkills;
    if (check === -1) {
      newSkills = [...recieveSkill[name], { ...skill, level }];
      setRecieveSkill({ ...recieveSkill, [name]: [...newSkills] });
    } else {
      newSkills = recieveSkill[name].filter(({ id }) => id !== skill.id);
      setRecieveSkill({ ...recieveSkill, [name]: [...newSkills] });
    }
  };
  const onSubmit = async ({ course }) => {
    const formData = new FormData();
    formData.append('title', course.title);
    formData.append('description', course.description);
    formData.append('image', course.image[0]);
    formData.append('category', course.category);
    formData.append(
      'languages',
      JSON.stringify(
        recieveSkill.languages.map((language) => ({
          id: language.id,
          image: language.image,
          score: language.score,
          level: language.level
        }))
      )
    );
    formData.append(
      'tools',
      JSON.stringify(
        recieveSkill.tools.map((tool) => ({
          id: tool.id,
          image: tool.image,
          score: tool.score,
          level: tool.level
        }))
      )
    );
    setShowLoading(true);
    let { data } = await courseService.create(formData);
    setShowLoading(false);
    if (data.status) {
      navigate('/admin/course');
    }
  };

  return (
    <div className="create-course mb-5">
      {showLoading && <Loading />}
      <Link to="/admin/course" className="inline-block btn-normal">
        <span className="mr-2">
          <i className="fa-solid fa-chevron-left"></i>
        </span>
        Back to form
      </Link>
      <h1 className="text-center font-semibold text-2xl">Create new Course</h1>
      <div className="flex justify-center">
        <form className="w-1/2" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <Input label="Title" name="course.title" register={register} />
          </div>
          <div className="mb-4">
            <label
              htmlFor="Description"
              className="block mb-2 text-sm font-semibold text-gray-900 "
            >
              Description
            </label>
            <textarea
              type="text"
              id="Description"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              required
              rows={3}
              autoComplete="true"
              {...register('course.description')}
            />
          </div>
          <div className="form-group my-4 flex items-center">
            <label
              htmlFor=""
              className="font-semibold inline-block min-w-[100px]"
            >
              Category
            </label>
            <select
              className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2"
              {...register('course.category')}
              defaultValue="0"
              required
            >
              <option value="0">Choose a category</option>
              <option value="1">UI/UX Designer</option>
              <option value="2">Front-End Development</option>
              <option value="3">Back-End Development</option>
              <option value="4">Devops Developement</option>
            </select>
          </div>
          <div className="flex items-center">
            <label
              htmlFor=""
              className="font-semibold inline-block min-w-[100px]"
            >
              Image
            </label>
            <div>
              <div className="flex items-center space-x-6 my-5">
                <label className="block">
                  <span className="sr-only">Choose profile photo</span>
                  <input
                    type="file"
                    className="block w-full text-slate-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-violet-50 file:text-violet-700
                  hover:file:bg-violet-100"
                    accept="image/*"
                    required
                    {...register('course.image')}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="mb-4">
            {watch('course.image') && watch('course.image').length > 0 && (
              <img
                src={URL.createObjectURL(watch('course.image')[0])}
                alt=""
                className="h-[100px] object-cover"
              />
            )}
          </div>
          <div className="mb-4 flex items-center">
            <SkillInput
              name="Languages"
              skills={languages}
              onAddSkill={addSkill}
            />
          </div>
          {recieveSkill.languages.length > 0 && (
            <div className="mb-4 flex items-center">
              {recieveSkill.languages.map((language, index) => {
                return (
                  <SkillItem
                    key={index}
                    image={language.image}
                    level={language.level}
                  />
                );
              })}
            </div>
          )}
          <div className="mb-4 flex items-center">
            <SkillInput name="Tools" skills={tools} onAddSkill={addSkill} />
          </div>
          {recieveSkill.tools.length > 0 && (
            <div className="mb-4 flex items-center">
              {recieveSkill.tools.map((tool, index) => {
                return (
                  <SkillItem
                    key={index}
                    image={tool.image}
                    level={tool.level}
                  />
                );
              })}
            </div>
          )}
          <button className="btn-main">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default CreateCourse;
