import request from '@/request';

import common from './common';

const userService = {
  ...common('users'),
  async notifications() {
    return await request.post('/users/notifications');
  },
  async clearNotifications() {
    return await request.post('/users/clear-notifications');
  },
  async changeSkill(data) {
    return await request.post('/users/change-skill', data);
  },
  async updateUserProfile(id, data) {
    return await request.post(`/users/update-profile/${id}`, data);
  },
  async getUserByCourseId(id) {
    const { data } = await request.get(`/users/user-course/${id}`);
    return data.users;
  },
  async rank(query, page, team = '') {
    return await request.get(
      `/rank?page=${page}&orderBy=${query}&team=${team}`
    );
  }
};

export { userService };
