import { useSelector } from 'react-redux';

function useCheck() {
  const { isAdmin, permissions } = useSelector((state) => state.permission);
  const check = (permission) => {
    return isAdmin || permissions.includes(permission);
  };
  return check;
}

export default useCheck;
