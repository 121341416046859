import { useEffect, useState } from 'react';

import Skeleton from '@/components/Skeleton';
import { CourseName } from '@/constants';
import { courseService } from '@/services';

import ListCourse from './components/ListCourse';

function Home() {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getAllCourses = async () => {
      setIsLoading(true);
      let { data } = await courseService.all();
      setIsLoading(false);
      setCourses(data.courses);
    };
    getAllCourses();
  }, []);

  return (
    <div className="home mb-10">
      {isLoading && <Skeleton />}
      <ListCourse courses={courses} name={CourseName.FE} />
      <ListCourse courses={courses} name={CourseName.BE} />
      <ListCourse courses={courses} name={CourseName.DevOps} />
    </div>
  );
}

export default Home;
