import { useLocation } from 'react-router-dom';

function NullLayout({ children }) {
  const location = useLocation();
  return (
    <div className="wrapper" key={location.pathname}>
      <main className="content">{children}</main>
    </div>
  );
}

export default NullLayout;
