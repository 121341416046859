const Table = ({ dataSource, columns }) => {
  return (
    <table className="table divide-y rounded-lg w-full overflow-x-auto">
      <thead className="bg-gray-50">
        <tr className="whitespace-nowrap text-left bg-gray-100">
          {columns.map((column, index) => (
            <th
              key={index}
              scope="col"
              className="py-3 px-2 text-sm text-gray-500"
            >
              {column.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white divide-y">
        {dataSource.map((data, index) => {
          return (
            <tr key={index} className="whitespace-nowrap">
              {columns.map((column, index) => {
                if (column.render) {
                  return (
                    <td key={index} className="py-4 px-2 text-sm">
                      {column.render(data[column.dataIndex], data)}
                    </td>
                  );
                }

                return (
                  <td key={index} className="py-4 px-2 text-sm">
                    {data[column.dataIndex]}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
