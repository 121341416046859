import '@/assets/css/override.css';

import { useEffect, useRef, useState } from 'react';
import Editor from 'react-markdown-editor-lite';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Loading from '@/components/Loading';
import MarkDown from '@/components/MarkDown';
import { lessonService } from '@/services';

import CodeSection from './components/CodeSection';
import QuizSection from './components/QuizSection';

const Create = () => {
  const { courseId } = useParams();
  const { lessonId } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const mdEditor = useRef(null);
  const [lesson, setLesson] = useState({
    title: '',
    duration: '',
    data: '',
    courseId: courseId,
    assignment_type: 'quiz',
    language: '',
    code_practice: '',
    quizzes: [
      {
        question: 'Question',
        answers: [
          { text: 'Answer 1', isCorrect: false },
          { text: 'Answer 2', isCorrect: false },
          { text: 'Answer 3', isCorrect: false },
          { text: 'Answer 4', isCorrect: false }
        ]
      }
    ]
  });
  const [showLoading, setShowLoading] = useState(false);
  const [section, setSection] = useState(
    searchParams.get('section') || 'content'
  );
  const handleEditorChange = ({ html, text }) => {
    setLesson({ ...lesson, data: text });
  };

  const addNewQuiz = (e) => {
    e.preventDefault();
    setLesson({
      ...lesson,
      quizzes: [
        ...lesson.quizzes,
        {
          question: 'Question',
          answers: [
            { text: 'Answer 1', isCorrect: false },
            { text: 'Answer 2', isCorrect: false },
            { text: 'Answer 3', isCorrect: true },
            { text: 'Answer 4', isCorrect: false }
          ]
        }
      ]
    });
  };

  const deleteQuiz = (e, quizId) => {
    e.preventDefault();
    if (lesson.quizzes.length === 1) return;
    let newQuizzes = lesson.quizzes.filter((quiz, index) => index !== quizId);
    setLesson({ ...lesson, quizzes: [...newQuizzes] });
  };

  const handleFileChange = (file) => {
    return new Promise((resolve) => {
      const uploadFile = async () => {
        let formData = new FormData();
        formData.append('image', file);
        setShowLoading(true);
        let { data } = await lessonService.handleImageUpload(formData);
        setShowLoading(false);
        return data.file_store;
      };
      resolve(uploadFile());
    });
  };

  const handleSubmit = async () => {
    if (lessonId) {
      await lessonService.update(lessonId, lesson);
      navigate(`/detail/${lesson.course_id}`);
    } else {
      await lessonService.create(lesson);
      navigate(`/detail/${courseId}`);
    }
  };

  const swapSection = (sectionName) => {
    // validate duration
    console.log(sectionName);
    if (
      sectionName === 'assignment' &&
      (lesson.duration.trim().length !== 5 || !lesson.duration.includes(':'))
    ) {
      toast.error('Invalid duration !!!', {
        position: 'top-right',
        autoClose: '1000'
      });
      return;
    }
    setSection(sectionName);
    searchParams.set('section', sectionName);
    setSearchParams(searchParams);
    // check data
  };

  const changeValue = (quizId, type, value, answerId) => {
    let newQuizzes;
    switch (type) {
      case 'question':
        newQuizzes = lesson.quizzes.map((quiz, index) => {
          if (index === quizId) {
            quiz = { ...quiz, question: value };
          }
          return quiz;
        });
        break;
      case 'answer':
        newQuizzes = lesson.quizzes.map((quiz, index) => {
          if (index === quizId) {
            let newAnswers = quiz.answers.map((answer, key) => {
              if (key === answerId) {
                answer = { ...answer, text: value };
              }
              return answer;
            });
            quiz = { ...quiz, answers: [...newAnswers] };
          }
          return quiz;
        });
        break;
      case 'check':
        newQuizzes = lesson.quizzes.map((quiz, index) => {
          if (index === quizId) {
            let newAnswers = quiz.answers.map((answer, key) => {
              if (key === answerId) {
                answer = { ...answer, isCorrect: value };
              } else {
                answer = { ...answer, isCorrect: false };
              }
              return answer;
            });
            quiz = { ...quiz, answers: [...newAnswers] };
          }
          return quiz;
        });
        break;
    }
    setLesson({ ...lesson, quizzes: [...newQuizzes] });
  };

  useEffect(() => {
    const getLesson = async () => {
      let { data } = await lessonService.find(lessonId);
      setLesson(data.lesson);
    };
    if (lessonId) {
      getLesson();
    }
  }, [lessonId]);

  return (
    <div className="create-lesson px-8 mt-2">
      {showLoading && <Loading />}
      {section === 'content' && (
        <div className="content">
          <input
            type="text"
            placeholder="Untitled"
            className="input text-3xl font-medium w-full"
            onChange={(e) => setLesson({ ...lesson, title: e.target.value })}
            value={lesson.title}
            required
          />
          <div className="flex justify-between">
            <input
              type="text"
              placeholder="Time taken: 05:41"
              className="input my-2"
              onChange={(e) =>
                setLesson({ ...lesson, duration: e.target.value })
              }
              value={lesson.duration}
              required
            />
            <button
              className="btn-main"
              onClick={() => swapSection('assignment')}
            >
              Assignment section
            </button>
          </div>
          <div>
            <Editor
              ref={mdEditor}
              value={lesson.data}
              placeholder="Nội dung bài học viết ở đây"
              style={{
                height: '78vh'
              }}
              onImageUpload={handleFileChange}
              onChange={handleEditorChange}
              renderHTML={(text) => <MarkDown source={text} />}
            />
          </div>
        </div>
      )}
      {section === 'assignment' && (
        <div className="assignment">
          <div className="flex justify-between items-center">
            <button
              className="inline-block btn-normal"
              onClick={() => swapSection('content')}
            >
              <span className="mr-2">
                <i className="fa-solid fa-chevron-left"></i>
              </span>
              Content section
            </button>
            <button className="btn-main" onClick={handleSubmit}>
              {courseId ? ' Publish' : 'Update'}
            </button>
          </div>
          <div className="container mx-auto">
            <h1 className="text-center">Assignments for Intern</h1>
            <form
              className="mx-auto w-2/3 my-3 overflow-y-scroll h-[75vh]"
              onSubmit={handleSubmit}
            >
              <div className="my-3 flex items-center">
                <input
                  id="number"
                  type="radio"
                  name="default-radio"
                  className="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                  onChange={() =>
                    setLesson({ ...lesson, assignment_type: 'quiz' })
                  }
                  checked={lesson.assignment_type === 'quiz'}
                />
                <label
                  htmlFor="number"
                  className="cursor-pointer ml-2 text-sm font-medium text-blue-500 bg-blue-200 px-2 py-1 rounded-xl"
                >
                  Quizzes
                </label>
              </div>
              <div className="my-3 flex items-center">
                <input
                  id="percent"
                  type="radio"
                  name="default-radio"
                  className="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                  onChange={() =>
                    setLesson({ ...lesson, assignment_type: 'code_practice' })
                  }
                  checked={lesson.assignment_type === 'code_practice'}
                />
                <label
                  htmlFor="percent"
                  className="cursor-pointer ml-2 text-sm font-medium text-red-500 bg-red-200 px-2 py-1 rounded-xl"
                >
                  Coding Practice
                </label>
              </div>
              {lesson.assignment_type === 'code_practice' && (
                <CodeSection lesson={lesson} setLesson={setLesson} />
              )}
              {lesson.assignment_type === 'quiz' && (
                <QuizSection
                  quizzes={lesson.quizzes}
                  addNewQuiz={addNewQuiz}
                  deleteQuiz={deleteQuiz}
                  changeValue={changeValue}
                />
              )}
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Create;
