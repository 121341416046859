const images = {
  avatar: require('@/assets/images/vunguyen.jpg'),
  background: require('@/assets/images/background.jpg'),
  background1: require('@/assets/images/background-1.jpg'),
  course: 'https://files.fullstack.edu.vn/f8-prod/courses/15/62f13d2424a47.png',
  challenge: require('@/assets/images/challenge.png'),
  best20: 'https://app.codesignal.com/user-icons/solved/solved_20.svg',
  best50: 'https://app.codesignal.com/user-icons/solved/solved_50.svg',
  best100: 'https://app.codesignal.com/user-icons/solved/solved_100.svg',
  best500: 'https://app.codesignal.com/user-icons/solved/solved_500.svg',
  best1k: 'https://app.codesignal.com/user-icons/solved/solved_1000.svg',
  trophy1:
    'https://app.codesignal.com/user-icons/interview_practice/ipm_topic_1.svg',
  trophy3:
    'https://app.codesignal.com/user-icons/interview_practice/ipm_topic_3.svg',
  trophy5:
    'https://app.codesignal.com/user-icons/interview_practice/ipm_topic_5.svg',
  trophy10:
    'https://app.codesignal.com/user-icons/interview_practice/ipm_topic_10.svg',
  score3: 'https://app.codesignal.com/user-icons/arcade/arcade_levels_3.svg',
  score10: 'https://app.codesignal.com/user-icons/arcade/arcade_levels_10.svg',
  score20: 'https://app.codesignal.com/user-icons/arcade/arcade_levels_20.svg',
  score50: 'https://app.codesignal.com/user-icons/arcade/arcade_levels_50.svg'
};

export default images;
