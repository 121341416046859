import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialOceanic } from 'react-syntax-highlighter/dist/esm/styles/prism';

function HighLight({ code }) {
  return (
    <SyntaxHighlighter language="javascript" style={materialOceanic}>
      {code}
    </SyntaxHighlighter>
  );
}

export default HighLight;
