import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Modal from '@/components/Modal';
import { useCheck } from '@/hooks';
import { roleService } from '@/services';

function Role() {
  const can = useCheck();
  const [roles, setRoles] = useState([]);
  const [roleName, setRoleName] = useState('');
  const [permissionIds, setPermissionIds] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    const getRole = async () => {
      let res = await roleService.all();
      setRoles(res.data.roles);
    };
    const getAllPermissions = async () => {
      let res = await roleService.getAllPermissions();
      let result = res.data.permissions;
      let newPermissions = [];
      let tempArray = [];
      for (let i = 0; i <= result.length; i++) {
        if (
          i === 0 ||
          tempArray.length === 0 ||
          result[i]?.name.includes(result[i - 1]?.name.split('_')[0])
        ) {
          tempArray.push(result[i]);
        } else {
          newPermissions = [...newPermissions, tempArray];
          tempArray = [];
          i--;
        }
      }
      setPermissions(newPermissions);
    };
    getRole();
    getAllPermissions();
  }, [reload]);

  const deleteRole = async (id) => {
    await roleService.delete(id);
    setRoles((prevRoles) => prevRoles.filter((role) => role.id !== id));
  };

  const viewPermission = (permissions, roleName) => {
    setShowModal(true);
    setRoleName(roleName);
    let newPermissionIds = permissions.reduce(
      (acc, permission) => [...acc, permission.id],
      []
    );
    setPermissionIds(newPermissionIds);
  };

  const changePermission = async () => {
    await roleService.changePermission({
      permission: permissionIds,
      roleName: roleName
    });
    setShowModal(false);
    setReload(!reload);
  };

  const handleChange = (id) => {
    if (permissionIds.includes(id)) {
      setPermissionIds((prevPermissionIds) =>
        prevPermissionIds.filter((value) => value !== id)
      );
    } else {
      setPermissionIds([...permissionIds, id]);
    }
  };

  return (
    <div className="role">
      <div className="my-3 flex justify-start">
        {can('role_create') && (
          <Link
            to="/admin/role/create"
            className="px-2 py-1 rounded-lg text-gray-900 bg-gray-200 font-semibold hover:bg-gray-300 transition-all"
          >
            <span className="mr-2">
              <i className="fa-solid fa-circle-plus"></i>
            </span>
            Create Role
          </Link>
        )}
      </div>
      <table className="table divide-y rounded-lg w-full overflow-x-auto">
        <thead className="bg-gray-50">
          <tr className="text-left bg-gray-100">
            <th scope="col" className="py-3 px-2 text-sm text-gray-500">
              Id
            </th>
            <th scope="col" className="py-3 px-2 text-sm text-gray-500">
              Rolename
            </th>
            <th scope="col" className="py-3 px-2 text-sm text-gray-500">
              Description
            </th>
            <th scope="col" className="py-3 px-2 text-sm text-gray-500">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y">
          {roles.map((role, index) => {
            return (
              <tr key={index}>
                <td className="py-4 px-2 text-sm font-semibold">#{role.id}</td>
                <td className="py-4 px-2 text-sm font-semibold">{role.name}</td>
                <td className="py-4 px-2 text-sm">
                  <ul>
                    {role.permissions.map((permission, index) => {
                      return (
                        <li key={index}>
                          <span className="inline-block mt-2 mr-2 p-1 text-xs text-green-700 font-semibold bg-green-100 rounded">
                            {permission.name}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                  <span className="text-2xl font-bold">
                    {role.permissions.length === 0 ? '*' : ''}
                  </span>
                </td>
                <td className="py-4 px-2">
                  <div className="flex items-center">
                    {can('role_edit') && (
                      <button
                        onClick={() =>
                          viewPermission(role.permissions, role.name)
                        }
                        className="outline-none px-3 py-1 text-sm rounded-full font-semibold text-green-500 bg-green-200"
                      >
                        <i className="fa-solid fa-user-pen"></i>
                      </button>
                    )}
                    {can('role_delete') && (
                      <button
                        className="mx-2 outline-none px-3 py-1 text-sm rounded-full font-semibold text-red-500 bg-red-200"
                        onClick={() => deleteRole(role.id)}
                      >
                        delete
                      </button>
                    )}
                    {can('role_edit') && (
                      <Link
                        to={`/admin/role/edit/${role.id}`}
                        className="outline-none px-3 py-1 text-sm rounded-full text-blue-600 bg-blue-200"
                      >
                        edit
                      </Link>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <div className="grid grid-cols-4 gap-5">
          {permissions.map((value, index) => {
            return (
              <div key={index} className="col-span-1">
                {value.map((permission, index) => {
                  return (
                    <div key={index}>
                      <input
                        type="checkbox"
                        value={permission.id}
                        id={permission.id}
                        name="permission"
                        onChange={() => handleChange(permission.id)}
                        checked={permissionIds.includes(permission.id)}
                      />
                      <label
                        htmlFor={permission.id}
                        className="inline-block mt-2 ml-2 cursor-pointer"
                      >
                        {permission.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="flex justify-end">
          <button className="btn-main mt-4" onClick={changePermission}>
            Save changes
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default Role;
