import axios from 'axios';

import { getAuthToken } from './utils/localStorageUtil';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'content-type': 'application/json'
  }
});

axiosClient.interceptors.request.use(
  (config) => {
    let token = getAuthToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403) {
      // window.location.href = '/error';
      window.location.href = '/';
    }
  }
);

export default axiosClient;
