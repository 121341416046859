import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { userService } from '@/services';
import { getAuthUser } from '@/utils/localStorageUtil';

function Header({ name }) {
  const userInfo = useRef(getAuthUser());
  const [user, setUser] = useState({
    image: '',
    full_name: '',
    point: 0
  });
  const [isShowNotificaton, setIsShowNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const clearNotification = async () => {
    await userService.clearNotifications();
    setIsShowNotification(false);
    setNotifications([]);
  };

  useEffect(() => {
    const getUser = async () => {
      let { data } = await userService.find(userInfo.current.id);
      setUser(data.user);
    };
    getUser();
  }, [userInfo.current.id]);

  useEffect(() => {
    const getNotifications = async () => {
      let { data } = await userService.notifications();
      setNotifications(data.notifications);
    };
    getNotifications();
  }, []);

  return (
    <header
      className={`flex justify-between items-center py-2 px-5 ${
        name === 'back' ? 'border-b' : ''
      } `}
    >
      {name === 'default' ? (
        <div className="flex items-center border rounded-full py-1 overflow-hidden">
          <span className="mx-2 text-gray-500">
            <i className="fa-solid fa-magnifying-glass"></i>
          </span>
          <input type="text" placeholder="Search..." className="input" />
        </div>
      ) : (
        <div className="ml-3 text-gray-500">
          <Link to="/">
            <i className="fa-solid fa-chevron-left"></i>
            <span className="ml-5">Back to Courses</span>
          </Link>
        </div>
      )}
      <div className="flex items-center mr-2">
        <div className="mx-4 bg-white px-2 py-1 rounded-full relative group text-gray-800 text-2xl cursor-pointer">
          <span onClick={() => setIsShowNotification(!isShowNotificaton)}>
            <i className="fa-regular fa-bell"></i>
          </span>
          <span className="absolute right-[2px] top-0 bg-red-200  text-red-600 font-semibold text-xs px-1.5 rounded-full">
            {notifications.length > 0 ? notifications.length : ''}
          </span>
          {isShowNotificaton && (
            <div>
              <div
                className="fixed inset-0"
                onClick={() => setIsShowNotification(false)}
              ></div>
              {notifications.length > 0 ? (
                <ul
                  className="absolute w-[500px] top-[calc(100%+10px)] rounded -right-[50px] bg-white shadow-[0_0_1px_1px_rgba(0,0,0,0.09)] z-10
                before:block before:absolute before:border-t before:border-l before:w-[16px] before:h-[16px] before:bg-white before:rotate-45 before:top-[-8px] before:right-[58px] before:z-0"
                >
                  {notifications.map(({ data }, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`/detail/${data.courseId}`}
                          className="notification flex items-center justify-between text-base p-3 rounded-md"
                        >
                          <div className="flex items-center justify-between w-full">
                            <span className="mr-2 text-blue-500">
                              <i className="fa-solid fa-rotate-right"></i>
                            </span>
                            <div>
                              <p className="">
                                You need to redo assignment in
                                <span className="ml-1 text-blue-500">
                                  Lesson #{data.lessonId}
                                </span>
                              </p>
                              <p>
                                Course:
                                <span className="ml-1 font-medium">
                                  {data.title}
                                </span>
                              </p>
                            </div>
                            <img
                              src={data.image}
                              alt=""
                              className="h-[70px] w-[70px] rounded object-cover"
                            />
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                  <li>
                    <div className="flex items-center justify-between w-full p-3">
                      <span className="text-sm">
                        Task Redo: {notifications.length}
                      </span>
                      <div
                        onClick={clearNotification}
                        className="text-sm text-right font-semibold text-red-500"
                      >
                        <i className="mr-1 fa-solid fa-circle-xmark"></i>
                        Clear notifications
                      </div>
                    </div>
                  </li>
                </ul>
              ) : (
                <div
                  className="flex flex-col justify-center items-center p-4 absolute w-[500px] top-[calc(100%+10px)] rounded right-[-50px] bg-white shadow-[0_0_1px_1px_rgba(0,0,0,0.09)] z-10
                  after:block after:absolute after:border-t after:border-l after:w-[16px] after:h-[16px] after:bg-white after:rotate-45 after:top-[-8px] after:right-[58px]"
                >
                  <span className="text-4xl">
                    <i className="fa-solid fa-bell"></i>
                  </span>
                  <p className="mt-4 text-lg text-blue-500 font-semibold">
                    You do not have any notifications yet.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex items-center">
          <img
            src={user.image}
            alt=""
            className="h-10 w-10 rounded-full object-cover"
          />
          <div className="ml-2 text-sm">
            <p className="font-semibold">{user.full_name}</p>
            <p className="text-gray-400">
              <span className="mr-1 text-amber-400 text-xs">
                <i className="fa-solid fa-coins"></i>
              </span>
              {user.point}
            </p>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
