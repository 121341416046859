import { useEffect, useState } from 'react';

import Modal from '@/components/Modal';
import SkillItem from '@/components/SkillItem';
import SkillInput from '@/pages/admin/courses/components/SkillInput';
import { languageService, toolService, userService } from '@/services';

const Skill = ({ name, personSkills, reload, onReload, isMe }) => {
  const [showModal, setShowModal] = useState(false);
  const [skillValue, setSkillValue] = useState([]);
  const [skills, setSkills] = useState([]);
  const handleAddSkill = (skill, name, level) => {
    let check = skillValue.findIndex(({ id }) => id === skill.id);
    let newSkills;
    if (check === -1) {
      setSkillValue([...skillValue, { ...skill, level }]);
    } else {
      newSkills = skillValue.filter(({ id }) => id !== skill.id);
      setSkillValue([...newSkills]);
    }
  };
  useEffect(() => {
    setSkillValue(personSkills);
  }, [personSkills]);

  useEffect(() => {
    const getLanguages = async () => {
      let { data } = await languageService.all();
      setSkills(data.languages);
    };
    const getTools = async () => {
      let { data } = await toolService.all();
      setSkills(data.tools);
    };
    if (name === 'Languages') {
      getLanguages();
    } else {
      getTools();
    }
  }, []);

  const handleSubmit = async () => {
    await userService.changeSkill({
      name: name.toLowerCase(),
      skills: skillValue.map((skill) => ({ id: skill.id, level: skill.level }))
    });
    setShowModal(false);
    onReload(!reload);
  };

  return (
    <div className="p-4 mb-5 bg-white">
      <div className="flex justify-between items-center pb-3 border-b">
        <span className="font-semibold text-gray-500">{name}</span>
        {isMe && (
          <span className="cursor-pointer" onClick={() => setShowModal(true)}>
            <i className="fa-solid fa-pen-to-square"></i>
          </span>
        )}
      </div>
      <div className="mt-2 grid grid-cols-3 gap-5">
        <div className="col-span-1">
          <span className="text-sm">Fundamental</span>
          {personSkills
            .filter(({ level }) => level === 1)
            .map((skill, index) => {
              return <SkillItem key={index} image={skill.image} />;
            })}
        </div>
        <div className="col-span-1">
          <span className="text-sm">Intermediate</span>
          {personSkills
            .filter(({ level }) => level === 2)
            .map((skill, index) => {
              return <SkillItem key={index} image={skill.image} />;
            })}
        </div>
        <div className="col-span-1">
          <span className="text-sm">Advanced</span>
          {personSkills
            .filter(({ level }) => level === 3)
            .map((skill, index) => {
              return <SkillItem key={index} image={skill.image} />;
            })}
        </div>
      </div>
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <SkillInput name={name} skills={skills} onAddSkill={handleAddSkill} />
        <div className="mt-2 flex flex-wrap">
          {skillValue.map((skill, index) => {
            return (
              <SkillItem key={index} image={skill.image} level={skill.level} />
            );
          })}
        </div>
        <button className="mt-5 btn-main" onClick={handleSubmit}>
          Save changes
        </button>
      </Modal>
    </div>
  );
};

export default Skill;
