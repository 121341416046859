import { useEffect, useState } from 'react';

import Course from '@/components/Course';
import EmptyCourse from '@/components/EmptyCourse';
import { courseService } from '@/services';

const MyClass = () => {
  const [courses, setCourses] = useState([]);
  useEffect(() => {
    const getAllCourses = async () => {
      let { data } = await courseService.learningCourse();
      setCourses(data.courses);
    };
    getAllCourses();
  }, []);
  return (
    <div className="myclass">
      {courses.length > 0 ? (
        <div className="grid grid-cols-12 gap-5">
          {courses.map((course, index) => {
            return (
              <div className="col-span-3" key={index}>
                <Course course={course} />
              </div>
            );
          })}
        </div>
      ) : (
        <EmptyCourse
          title="Empty"
          description="When you learn a course, they will appear on you profile"
        />
      )}
    </div>
  );
};

export default MyClass;
