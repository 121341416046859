import request from '@/request';
import { getAuthUser } from '@/utils/localStorageUtil';

import common from './common';

const courseService = {
  ...common('courses'),
  async enrollCourse(courseId) {
    return await request.post(`/courses/enroll/${courseId}`);
  },
  async learningCourse(userId = getAuthUser().id) {
    return await request.get(`/courses/learning-course/${userId}`);
  },
  async coursesForAdmin() {
    const { data } = await request.post('/courses/course-admin');
    return data.courses;
  }
};

export { courseService };
