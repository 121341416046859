function Pagination({ currentPage, lastPage, changePage }) {
  return (
    <nav className="my-5">
      <ul className="inline-flex items-center -space-x-px">
        <li>
          <span
            onClick={() => changePage(currentPage - 1)}
            className="cursor-pointer mx-1 rounded-md py-2 px-3 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
          >
            <i className="fa-solid fa-chevron-left"></i>
          </span>
        </li>
        {[...Array(lastPage)].map((value, index) => {
          return (
            <li key={index}>
              <span
                className={`cursor-pointer mx-1 rounded-md py-2 px-3 border border-gray-300
                 ${
                   index + 1 === currentPage ? 'text-blue-500 bg-blue-300' : ''
                 }`}
                onClick={() => changePage(index + 1)}
              >
                {index + 1}
              </span>
            </li>
          );
        })}

        <li>
          <span
            onClick={() => changePage(currentPage + 1)}
            className="cursor-pointer mx-1 rounded-md py-2 px-3 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
          >
            <i className="fa-solid fa-chevron-right"></i>
          </span>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;
