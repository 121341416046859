const StorageKey = {
  authUser: '@auth:user',
  authToken: '@auth:token'
};

const IconStatus = {
  retake: 'fa-xmark text-red-500',
  learning: '',
  submitted: 'fa-spinner text-yellow-500',
  completed: 'fa-circle-check text-green-500',
  locked: 'fa-lock text-black'
};

const LessonStatus = {
  retake: 'px-2 py-1 text-sm font-semibold rounded-md text-red-800 bg-red-100',
  learning:
    'px-2 py-1 text-sm font-semibold rounded-md text-blue-800 bg-blue-100',
  submitted:
    'px-2 py-1 text-sm font-semibold rounded-md text-yellow-800 bg-yellow-100',
  completed:
    'px-2 py-1 text-sm font-semibold rounded-md text-green-800 bg-green-100',
  locked: 'px-2 py-1 text-sm font-semibold rounded-md text-gray-800 bg-gray-100'
};

const Team = {
  'l&d': 'text-blue-500 bg-blue-100',
  php: 'text-green-500 bg-green-100',
  javascript: 'text-gray-500 bg-gray-100',
  'ruby on rails': 'text-yellow-500 bg-yellow-100',
  python: 'text-purple-500 bg-purple-100',
  mobile: 'text-pink-500 bg-pink-100'
};

const CourseName = {
  FE: 'Front-End Development',
  BE: 'Back-End Development',
  DevOps: 'Devops Development'
};

export { CourseName, IconStatus, LessonStatus, StorageKey, Team };
