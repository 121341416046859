import Course from '@/components/Course';
import EmptyCourse from '@/components/EmptyCourse';

const ListCourse = ({ courses, name }) => {
  if (courses.length === 0) return null;
  const checkCourseNameEmpty = () =>
    courses.filter((course) => course.category === name).length;

  return (
    <div>
      <h1 className="my-4 font-bold">{name}</h1>
      <div>
        <div className="grid grid-cols-12 gap-5">
          {courses
            .filter((course) => course.category === name)
            .map((course, index) => {
              return (
                <div className="col-span-3" key={index}>
                  <Course course={course} />
                </div>
              );
            })}
        </div>
        {checkCourseNameEmpty() === 0 && (
          <EmptyCourse
            title="Wating for mentor"
            description="Courses will be comming soon"
          />
        )}
      </div>
    </div>
  );
};

export default ListCourse;
