import { Link } from 'react-router-dom';

function Course({ course }) {
  return (
    <Link
      to={`/detail/${course.id}`}
      className="block border rounded overflow-hidden relative"
    >
      <img
        src={course.image}
        alt=""
        className="w-full h-[130px] md:h-[160px] lg:h-[180px] object-cover"
      />
      <div className="p-2">
        <h3 className="my-1 text-ellipsis overflow-hidden whitespace-nowrap">
          {course.title}
        </h3>
        <p className="my-1 text-gray-500">
          {course.lessonLength} lessons | {course.duration}
        </p>
        <div className="flex items-center justify-between my-1">
          <span>Mentor: {course.instractor}</span>
          <span className="text-main font-semibold">{course.team}</span>
        </div>
        {course.study?.status === 'learning' && (
          <div className="flex justify-center absolute top-2 right-0">
            <span className="mx-2 text-xs inline-block p-1 rounded-full bg-blue-100 text-blue-500">
              {Math.floor(
                ((course.current_lesson || 0) / course.lessonLength) * 100
              )}
              %
            </span>
          </div>
        )}
        {course.study?.status === 'completed' && (
          <div className="flex justify-center absolute top-2 right-0">
            <span className="mx-2 text-xl inline-block rounded-full text-green-500">
              <i className="fa-sharp fa-solid fa-circle-check"></i>
            </span>
          </div>
        )}
      </div>
    </Link>
  );
}

export default Course;
