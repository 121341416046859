import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { userService } from '@/services';

const EditProfile = () => {
  const { id } = useParams();
  const [url, setUrl] = useState('');
  const [user, setUser] = useState({
    full_name: '',
    username: '',
    position: '',
    phone: '',
    current_role: 0,
    number_experice: 0,
    image: ''
  });

  const handleFileChange = (e) => {
    let file = e.target.files[0];
    setUser({ ...user, image: file });
    setUrl(URL.createObjectURL(file));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    for (const [key, value] of Object.entries(user)) {
      formData.append(key, value);
    }
    let { data } = await userService.updateUserProfile(id, formData);
    if (data.status) {
      toast.success('Update user successfully!', {
        autoClose: 2000,
        position: 'top-right'
      });
    }
  };

  useEffect(() => {
    const getUser = async () => {
      let { data } = await userService.find(id);
      setUser(data.user);
    };
    getUser();
  }, [id]);

  return (
    <div className="edit-profile">
      <div className="container mx-auto">
        <h2 className="text-center my-3">Edit Profile</h2>
        <div className="flex justify-center">
          <form action="" className="w-[800px] mt-3" onSubmit={handleSubmit}>
            <div className="flex items-center mx-2 my-3">
              <label
                htmlFor=""
                className="font-semibold inline-block min-w-[100px]"
              >
                Avatar
              </label>
              <div>
                <div className="flex items-center my-5">
                  <label className="block">
                    <span className="sr-only">Choose profile photo</span>
                    <input
                      type="file"
                      className="block w-full text-slate-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-violet-50 file:text-violet-700
                  hover:file:bg-violet-100"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </label>
                  {url && (
                    <div>
                      <img
                        src={url}
                        className="w-[100px] h-[100px] object-cover rounded-full"
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex w-full my-3">
              <div className="form-group w-1/2 mx-2">
                <label htmlFor="" className="font-semibold inline-block mb-2">
                  Full Name
                </label>
                <input
                  type="text"
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                  onChange={(e) =>
                    setUser({ ...user, full_name: e.target.value })
                  }
                  value={user.full_name}
                />
              </div>
              <div className="form-group w-1/2 mx-2">
                <label htmlFor="" className="font-semibold inline-block mb-2">
                  User Name
                </label>
                <input
                  type="text"
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                  onChange={(e) =>
                    setUser({ ...user, username: e.target.value })
                  }
                  value={user.username}
                />
              </div>
            </div>
            <div className="flex w-full my-3">
              <div className="form-group w-1/2 mx-2">
                <label htmlFor="" className="font-semibold inline-block mb-2">
                  Postion
                </label>
                <input
                  type="text"
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                  onChange={(e) =>
                    setUser({ ...user, position: e.target.value })
                  }
                  value={user.position}
                />
              </div>
              <div className="form-group w-1/2 mx-2">
                <label htmlFor="" className="font-semibold inline-block mb-2">
                  Phone
                </label>
                <input
                  type="text"
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                  onChange={(e) => setUser({ ...user, phone: e.target.value })}
                  value={user.phone}
                />
              </div>
            </div>

            <div className="flex w-full mx-2 my-4">
              <div className="form-group w-1/2">
                <label htmlFor="" className="inline-block font-semibold mr-2">
                  Role
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2"
                  onChange={(e) =>
                    setUser({ ...user, current_role: e.target.value })
                  }
                  value={user.current_role}
                >
                  <option value="0">Choose a role</option>
                  <option value="Mobile Applications Developer">
                    Mobile Applications Developer
                  </option>
                  <option value="Frontend/UI Developer">
                    Frontend/UI Developer
                  </option>
                  <option value="Backend Developer">Backend Developer</option>
                  <option value="Full-Stack Developer">
                    Full-Stack Developer
                  </option>
                  <option value="QA/Test Engineer">QA/Test Engineer</option>
                  <option value="DevOps Engineer">DevOps Engineer</option>
                </select>
              </div>
              <div className="form-group w-1/2">
                <label htmlFor="" className="inline-block font-semibold mr-2">
                  Experience
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2"
                  onChange={(e) =>
                    setUser({ ...user, number_experience: e.target.value })
                  }
                  value={user.number_experience}
                >
                  <option value="0">Choose an experiences</option>
                  <option value="< 1 year">1 year</option>
                  <option value="1 - 2 years">1 - 2 years</option>
                  <option value="3 - 5 years">3 - 5 years</option>
                  <option value="6 - 10 years">6 - 10 years</option>
                  <option value="11+ years">11+ years</option>
                </select>
              </div>
            </div>
            <div>
              <button className="mt-5 btn-main">Save changes</button>
              <Link to={`/profile/${id}`} className="mt-2 btn-normal ml-3">
                <span className="mr-2 text-sm">
                  <i className="fa-solid fa-chevron-left"></i>
                </span>
                Back to Profile
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
