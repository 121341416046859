import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  permissions: [],
  isAdmin: false
};

export const permissionSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addPermissions(state, action) {
      return {
        ...state,
        isAdmin: action.payload.permissions.length > 0 ? false : true,
        permissions: [...action.payload.permissions]
      };
    }
  }
});

// Action creators are generated for each case reducer function
export const { addPermissions } = permissionSlice.actions;

export default permissionSlice.reducer;
